import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

const AboutVideo = ({ name, title, videoSrc }) => {
  return (
    <div className="row about-item" key={videoSrc}>
      <div className="col-lg-5 align-items-center col-lg-5 d-flex  item-text-wrapper">
        <div className="item-text">
          <h3 lang="tr">{name}</h3>
          <h4>{title}</h4>
        </div>
      </div>
      <div className="col-lg-7">
        <div className="item-media">
          <div className="image-overlay"></div>
          <Vimeo
            video={videoSrc}
            showTitle={false}
            className="about-video"
            responsive={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutVideo;

import React, { Component } from 'react';
import Slider from 'react-slick';
import InnerPage from '../InnerPageWrapper';
import './NewsEvent.scss';
import News1 from '../../assets/images/news-1.jpg';
import ArrowNext from '../../assets/images/arrowNext.svg';
import ArrowPrev from '../../assets/images/arrowPrev.svg';

class NewsEvent extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const NextArrow = props => {
      const { className, onClick } = props;
      return (
        <div className={className} onClick={onClick}>
          <img src={ArrowNext} alt="slider-next" />
        </div>
      );
    };

    const PrevArrow = props => {
      const { className, onClick } = props;
      return (
        <div className={className} onClick={onClick}>
          <img src={ArrowPrev} alt="slider-prev" />
        </div>
      );
    };

    const newsSliderSettings = {
      className: 'news-slider',
      slidesToShow: 4,
      speed: 500,
      initialSlide: 0,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      beforeChange: (current, next) => {
        console.log('current => ', current, 'next => ', next);
      }
    };

    return (
      <InnerPage>
        <div className="news-event-wrapper">
          <div className="content-padding-x">
            <div className="news-header">
              <div className="title-news">news & event</div>
              <div className="news-filter"></div>
            </div>
          </div>
          <div className="content-padding-left">
            <Slider
              ref={slider => (this.slider = slider)}
              {...newsSliderSettings}
            >
              <div className="news-item">
                <div
                  className="news-cover"
                  style={{ backgroundImage: `url(${News1})` }}
                ></div>
                <div className="news-tag">
                  <div className="tag-category">news</div>
                  <div className="tag-date">June 16, 2019</div>
                </div>
                <div className="news-details">
                  <h3>Since 2000,1</h3>
                  <p>
                    Since 2000, we have successfully built and furnished more
                    than 13 ships and yachts which are all between 12 to 120
                    meters.
                  </p>
                </div>
              </div>
              <div className="news-item">
                <div
                  className="news-cover"
                  style={{ backgroundImage: `url(${News1})` }}
                ></div>
                <div className="news-tag">
                  <div className="tag-category">news</div>
                  <div className="tag-date">June 16, 2019</div>
                </div>
                <div className="news-details">
                  <h3>Since 2000,</h3>
                  <p>
                    Since 2000, we have successfully built and furnished more
                    than 13 ships and yachts which are all between 12 to 120
                    meters.
                  </p>
                </div>
              </div>
              <div className="news-item">
                <div
                  className="news-cover"
                  style={{ backgroundImage: `url(${News1})` }}
                ></div>
                <div className="news-tag">
                  <div className="tag-category">news</div>
                  <div className="tag-date">June 16, 2019</div>
                </div>
                <div className="news-details">
                  <h3>Since 2000,</h3>
                  <p>
                    Since 2000, we have successfully built and furnished more
                    than 13 ships and yachts which are all between 12 to 120
                    meters.
                  </p>
                </div>
              </div>
              <div className="news-item">
                <div
                  className="news-cover"
                  style={{ backgroundImage: `url(${News1})` }}
                ></div>
                <div className="news-tag">
                  <div className="tag-category">news</div>
                  <div className="tag-date">June 16, 2019</div>
                </div>
                <div className="news-details">
                  <h3>Since 2000,</h3>
                  <p>
                    Since 2000, we have successfully built and furnished more
                    than 13 ships and yachts which are all between 12 to 120
                    meters.
                  </p>
                </div>
              </div>
              <div className="news-item">
                <div
                  className="news-cover"
                  style={{ backgroundImage: `url(${News1})` }}
                ></div>
                <div className="news-tag">
                  <div className="tag-category">news</div>
                  <div className="tag-date">June 16, 2019</div>
                </div>
                <div className="news-details">
                  <h3>Since 2000,5</h3>
                  <p>
                    Since 2000, we have successfully built and furnished more
                    than 13 ships and yachts which are all between 12 to 120
                    meters.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </InnerPage>
    );
  }
}

export default NewsEvent;

import React from 'react';
import VideoElement from '../../Video';
import Page from '../../../layouts/Page/Page';
import './FieldFeatures.scss';
import Mobile5 from '../../../assets/images/mobile_5.png';
import Poster from '../../../assets/images/poster_4.png';

const FieldFeatures = props => {
  return (
    <Page>
      <div className="field-features-wrapper">
        {window.innerWidth > 768 ? (
          <div>
            <VideoElement
              poster={Poster}
              videoSrc="http://www.blaundusyachts.com/videos/blaundus_5_1.mp4"
            />
             <div
              className="home-part-pre"
              style={{
                backgroundImage: `url(${Poster})`
              }}
            ></div>
          </div>
        ) : (
          <div
            className="has-background-image"
            style={{
              backgroundImage: `url(${Mobile5})`,
              height: props.mobile100vh
            }}
          ></div>
        )}
      </div>
    </Page>
  );
};

export default FieldFeatures;

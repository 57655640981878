import React, { useState } from 'react';
import IsScrolling from 'react-is-scrolling';
import { useStore } from '../../lib/Store/Store';
import { toggleAppMenu } from '../../lib/Store/actions';
import './TopNavMenu.scss';
import LogoImage from '../../assets/images/blaundus-logo-siyah.svg';
import LogoSecondary from '../../assets/images/blaundus-logo-secondary.svg';
import { Link } from 'react-router-dom';

const TopNavMenu = ({
  colorStyle,
  onTop,
  homePageMenu,
  isScrolling,
  isScrollingDown,
  isScrollingUp
}) => {
  const [state, dispatch] = useStore();

  const [isMenuActive, toggleMenuActive] = useState(false);
  const [language, setLanguage] = useState('en');
  const menuClass = isMenuActive ? ' is-active' : '';
  const EnButtonClass = language === 'en' ? ' is-active' : '';
  const TrButtonClass = language === 'tr' ? ' is-active' : '';
  const languageSelectionClass = !isMenuActive ? ' is-active' : '';

  return (
    <div className={`TopNavMenu ${colorStyle || ''} ${onTop ? 'onTop' : ''}`}>
      <div className="Logo">
        <button
          type="button"
          onClick={() => {
            window.location.assign("/");;
          }}
        >
          <img
            src={
              (onTop && colorStyle) || (homePageMenu && colorStyle)
                ? LogoSecondary
                : LogoImage
            }
            alt="Blaundus Logo"
          />{' '}
        </button>
      </div>
      <div className="RightMenu">
        <div className={'LanguageMenu' + languageSelectionClass}>
          <button
            className={'LanguageButton' + EnButtonClass}
            type="button"
            onClick={() => {
              setLanguage('en');
            }}
          >
            EN
          </button>
          <span className="LanguageButtonseparator">|</span>
          <button
            className={'LanguageButton' + TrButtonClass}
            type="button"
            onClick={() => {
              setLanguage('tr');
            }}
          >
            TR
          </button>
        </div>
        <span className={`hamburger-text d-none d-md-block ${menuClass}`}>
          MENU
        </span>
        <div className="MenuButton">
          <button
            className={`CloseButton ${menuClass}`}
            type="button"
            onClick={() => {
              toggleMenuActive(!isMenuActive);
              dispatch(toggleAppMenu());
            }}
          >
            CLOSE
          </button>
          <button
            className={'hamburger hamburger--collapse' + menuClass}
            type="button"
            onClick={() => {
              toggleMenuActive(!isMenuActive);
              dispatch(toggleAppMenu());
            }}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default IsScrolling(TopNavMenu);

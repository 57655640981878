import React from 'react';
import VideoElement from '../../components/Video';
import './BeachClub.scss';
import Page from '../Page/Page';
import Mobile6 from '../../assets/images/mobile_6.png';
import Poster from '../../assets/images/poster_5.png';

const BeachClub = props => {
  return (
    <Page>
      {window.innerWidth > 768 ? (
        <div>
          <VideoElement
            poster={Poster}
            videoSrc="http://www.blaundusyachts.com/videos/blaundus_6_1.mp4"
          />
          <div
            className="home-part-pre"
            style={{
              backgroundImage: `url(${Poster})`
            }}
          ></div>
        </div>
      ) : (
        <div
          className="has-background-image"
          style={{
            backgroundImage: `url(${Mobile6})`,
            height: props.mobile100vh
          }}
        ></div>
      )}
    </Page>
  );
};

export default BeachClub;

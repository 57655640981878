import React, { Component } from 'react';
import Slider from 'react-slick';
import InnerPage from '../InnerPageWrapper';
import './NewsDetail.scss';
import CloseIcon from '../../assets/images/close-icon.svg';
import ArrowNext from '../../assets/images/arrowNext.svg';
import ArrowPrev from '../../assets/images/arrowPrev.svg';
import News1 from '../../assets/images/news-1.jpg';

class NewsDetail extends Component {
  render() {
    const NextArrow = props => {
      const { className, onClick } = props;
      return (
        <div className={className} onClick={onClick}>
          <img src={ArrowNext} alt="slider-next" />
        </div>
      );
    };

    const PrevArrow = props => {
      const { className, onClick } = props;
      return (
        <div className={className} onClick={onClick}>
          <img src={ArrowPrev} alt="slider-prev" />
        </div>
      );
    };
    const newsDetailSlider = {
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      infinite: false
    };

    return (
      <InnerPage>
        <div className="news-detail-wrapper">
          <button className="btn-icon-only">
            <img src={CloseIcon} alt="close specs main" />
          </button>
          <div className="">
            <Slider {...newsDetailSlider}>
              <div className="news-detail-item content-padding-x">
                <div className="row">
                  <div className="col-xl-6">
                    <div
                      className="detail-image"
                      style={{ backgroundImage: `url(${News1})` }}
                    ></div>
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex detail-text flex-column h-100 justify-content-between">
                      <div>
                        <div className="title-news">Title</div>
                        <div className="text-news">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Aenean commodo ligula eget dolor. Aenean massa.
                          Cum sociis natoque penatibus et magnis dis parturient
                          montes, nascetur ridiculus mus. Donec quam felis,
                          ultricies nec, pellentesque eu.
                        </div>
                      </div>
                      <div className="d-flex justify-content-between news-footer">
                        <div className="news-category">News</div>
                        <div className="news-date">June 18, 2019</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="news-detail-item content-padding-x">
                <div className="row">
                  <div className="col-xl-6">
                    <div
                      className="detail-image"
                      style={{ backgroundImage: `url(${News1})` }}
                    ></div>
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex detail-text flex-column h-100 justify-content-between">
                      <div>
                        <div className="title-news">Title</div>
                        <div className="text-news">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Aenean commodo ligula eget dolor. Aenean massa.
                          Cum sociis natoque penatibus et magnis dis parturient
                          montes, nascetur ridiculus mus. Donec quam felis,
                          ultricies nec, pellentesque eu.
                          la eget dolor. Aenean massa.
                          Cum sociis natoque penatibus et magnis dis parturient
                          montes, nascetur ridiculus mus. Donec quam felis,
                          ultricies nec, pellentesque eu.
                        </div>
                      </div>
                      <div className="d-flex justify-content-between news-footer">
                        <div className="news-category">News</div>
                        <div className="news-date">June 18, 2019</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </InnerPage>
    );
  }
}

export default NewsDetail;

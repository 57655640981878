import React, { useState } from 'react';
import Technical from '../Technical';
import VideoElement from '../../components/Video';
import PlusIconAqua from '../../assets/images/plus-icon-aqua.svg';
import Page from '../Page/Page';
import Mobile3 from '../../assets/images/mobile_3.png';

const TechnicalSneak = props => {
  const [technicalActive, setTechnicalActive] = useState(false);

  const setTechnical = val => {
    setTechnicalActive(val);
  };

  return (
    <Page colorStyle={'secondary'}>
      {technicalActive && <Technical setTechnical={setTechnical} />}
      {!technicalActive && (
        <div>
          <div className="slider-video-button">
            <button
              className="alpha"
              onClick={() => {
                setTechnical(true);
              }}
            >
              <span>Specification</span>
              <img src={PlusIconAqua} />
            </button>
          </div>
          {window.innerWidth > 768 ? (
            <VideoElement videoSrc="http://www.blaundusyachts.com/videos/blaundus_3_1.mp4" />
          ) : (
            <div
              className="has-background-image"
              style={{
                backgroundImage: `url(${Mobile3})`,
                height: props.mobile100vh
              }}
            ></div>
          )}
        </div>
      )}
    </Page>
  );
};

export default TechnicalSneak;

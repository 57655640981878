import React from 'react';

const HeroInner = props => (
  <div className="hero-banner-inner">
    <div className="banner-text">
      <div className="title">
        Blaundus
        <span>
          B<span>{props.name}</span>
        </span>
      </div>
      <div className="message">{props.message}</div>
    </div>
    <div className="banner-image">
      <img src={props.image} alt={`Blaudus ${props.name}`} className="img-fluid" />
    </div>
  </div>
);

export default HeroInner;

import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import InnerPage from '../InnerPageWrapper';
import CaptionedImage from '../../components/CaptionedImage';
import HeroBanner from '../../components/HeroBanner';
import PlusIcon from '../../assets/images/plus-icon-small.svg';
import './NewBuild.scss';
import Blaundus26 from '../../assets/images/blaundus-b26.jpg';
import Blaundus30 from '../../assets/images/blaundus-b30.jpg';
import HeroBlaundus from '../../assets/images/blaundus-yacht.png';
import SlidingImage1 from '../../assets/images/beach-club-1.jpg';
import SlidingImage2 from '../../assets/images/fly-bridge-1.jpg';
import SlidingImage3 from '../../assets/images/exterior-7.jpg';
import SlidingImage4 from '../../assets/images/terrace-4.jpg';

const NewBuild = () => {
  /*   const onlyImagesSettings = {
    className: 'only-image-slider',
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: '100px',
    autoplay: true,
    autoplaySpeed: 0,
    speed: 30000,
    cssEase: "linear"
  }; */

  return (
    <InnerPage>
      <div className="">
        <div className="container-fluid new-build-wrapper">
          <div className="row align-items-center content-padding-x">
            <div className="col-xl-6 title-new-build">New Build</div>
            <div className="col-xl-6">
              <div className="text-new-build">
                We are building <span>your dreams.</span>
              </div>
            </div>
          </div>
          <div className="row hero-banners d-none d-md-flex">
            <div className="col-xl-6">
              <HeroBanner
                image={HeroBlaundus}
                name="30"
                linked={true}
                message="In build"
              />
            </div>
            <div className="col-xl-6">
              <HeroBanner
                image={HeroBlaundus}
                name="26"
                message="Coming soon"
              />
            </div>
          </div>
          <div className="row hero-banner-mobile d-flex d-md-none">
            <div className="col-12">
              <div className="image-wrapper">
                <img src={HeroBlaundus} />
                <div className="image-wrapper-background"></div>
              </div>
            </div>
            <div className="col-12">
              <div className="hero-banner-text">
                <span>
                  Blaundus B<span>30</span>
                </span>
                <Link to="/blaundus-b30">
                  <button>
                    Detail
                    <img src={PlusIcon} />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row hero-banner-mobile d-flex d-md-none">
            <div className="col-12">
              <div className="image-wrapper">
                <img src={HeroBlaundus} />
                <div className="image-wrapper-background"></div>
              </div>
            </div>
            <div className="col-12">
              <div className="hero-banner-text">
                <span>
                  Blaundus B<span>26</span>
                </span>
                {/* <button>
                  Detail
                  <img src={PlusIcon} />
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*       <div className="has-dark-background">
        <div className="content-padding-x">
          <div className="container-fluid content-padding-y">
            <div className="row pb-5">
              <div className="col-xl-6 title-fleet">Fleet</div>
              <div className="col-xl-6">
                <div className="text-fleet">
                  Dreams that already <br /> <span>come true...</span>
                </div>
                <p className="text-fleet--small">
                  Take a look at our most recently completed,
                  <br /> brand new projects.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <CaptionedImage
                  image={Blaundus26}
                  className="img-fluid"
                  caption="Blaundus b26"
                />
              </div>
              <div className="col-xl-6">
                <CaptionedImage
                  image={Blaundus30}
                  className="img-fluid"
                  caption="Blaundus b30"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="new-build-slider">
        <Slider {...onlyImagesSettings}>
          <div>
            <img src={SlidingImage1} alt="sliding" className="img-fluid" />
          </div>
          <div>
            <img src={SlidingImage2} alt="sliding" className="img-fluid" />
          </div>
          <div>
            <img src={SlidingImage3} alt="sliding" className="img-fluid" />
          </div>
          <div>
            <img src={SlidingImage4} alt="sliding" className="img-fluid" />
          </div>
        </Slider>
      </div> */}
    </InnerPage>
  );
};

export default NewBuild;

import React, { Component } from 'react';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './HorizontalSlider.scss';
import ArrowNext from '../../assets/images/arrowNext.svg';
import ArrowPrev from '../../assets/images/arrowPrev.svg';
import ImgZoom from '../../assets/images/img-zoom-icon.svg';

class HorizontalSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 1,
      isOpen: false,
      currentImg: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.slider.slickGoTo(0);
    }
  }

  handleClickImage = (e, image) => {
    e && e.persist();

    this.setState({
      currentImg: image
    });
  };

  handleCloseModal = e => {
    e && e.persist();

    this.setState({
      currentImg: ''
    });
  };

  render() {
    const {
      className,
      overflowWidth,
      centerMode,
      slidesToShow,
      sliderData,
      infinite,
      showCount,
      customArrow
    } = this.props;

    const NextArrow = props => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, right: overflowWidth * 2 + 15 || '0' }}
          onClick={onClick}
        >
          <img src={customArrow || ArrowNext} alt="slider-next" />
        </div>
      );
    };

    const PrevArrow = props => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, right: overflowWidth * 2 + 30 || '0' }}
          onClick={onClick}
        >
          <img src={customArrow || ArrowPrev} alt="slider-prev" />
        </div>
      );
    };

    const settings = {
      className: className || '',
      dots: false,
      arrows: true,
      lazyLoad: true,
      infinite: infinite || false,
      slidesToShow: slidesToShow || 1,
      centerMode: centerMode || false,
      centerPadding: overflowWidth || '0',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      afterChange: current => this.setState({ currentSlide: current + 1 }),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: true,
            arrows: false,
            slidesToShow: 1,
            centerPadding:30
          }
        }
      ]
    };

    return (
      <>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {sliderData &&
            Object.keys(sliderData).map(idx => (
              <div key={idx}>
                <div className="slider-item">
                  {sliderData[idx].title && (
                    <div
                      className="slider-title"
                      dangerouslySetInnerHTML={{
                        __html: sliderData[idx].title
                      }}
                    />
                  )}
                  <img
                    src={sliderData[idx].image}
                    className="img-fluid"
                    alt={sliderData[idx].title}
                  />
                  <button
                    className="img-zoom-button"
                    onClick={e =>
                      this.handleClickImage(e, sliderData[idx].image)
                    }
                  >
                    <img src={ImgZoom} alt="zoom in" />
                  </button>
                </div>
              </div>
            ))}
        </Slider>
        {this.state.currentImg && (
          <Lightbox
            mainSrc={this.state.currentImg}
            onCloseRequest={this.handleCloseModal}
          />
        )}
        {showCount && (
          <div className="count-label">
            <span className="current">{this.state.currentSlide}</span>
            <span className="separator">/</span>
            <span className="total">{sliderData.length}</span>
          </div>
        )}
      </>
    );
  }
}

export default HorizontalSlider;

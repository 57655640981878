import React from 'react';

const LinkToExternal = ({ children, href, icon, iconAlt }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer noopener">
      {icon ? <img src={icon} alt={iconAlt} /> : null}
      {children}
    </a>
  );
};
export default LinkToExternal;

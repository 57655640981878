import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useStore } from '../../lib/Store/Store';
import Footer from '../Footer';
import './InnerPage.scss';
import Header from '../Header';

const InnerPage = ({ children, colorStyle }) => {
  const [state, dispatch] = useStore();
  const menuActive = state.appMenuIsActive;
  const [scrollUp, setScrollUp] = useState(true);
  const [scrollTop, setScrollTop] = useState(true);

  const [handleWheel] = useDebouncedCallback(e => {
    document.body.scrollTop === 0 ? setScrollTop(true) : setScrollTop(false);
    e.deltaY < 0 ? setScrollUp(true) : setScrollUp(false);
  }, 10);

  const detectScroll = () => {
    let lastScroll = 0;
    let currentScroll =
      document.documentElement.scrollTop || document.body.scrollTop;
    console.log(document.body.scrollTop);

    /* if (currentScroll > 0 && lastScroll <= currentScroll) {
      lastScroll = currentScroll;
      console.log('down');
    } else {
      console.log('up');
    } */
  };

  useEffect(() => {
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => window.removeEventListener('wheel', handleWheel);
  }, [handleWheel]);

  return (
    <>
      <Header
        colorStyle={colorStyle}
        visibility={scrollUp}
        onTop={scrollTop}
        menuActive={menuActive}
      />
      <main className="inner-page">{children}</main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default InnerPage;

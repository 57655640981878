import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Store from './lib/Store/Store';

import Home from './layouts/Home/Home';
import About from './layouts/About/About';
import NewBuild from './layouts/NewBuild';
import NavalArchitecture from './layouts/NavalArchitecture';
import BlaundusB30 from './layouts/BlaundusB30';
import BlaundusB26 from './layouts/BlaundusB30/b26';
import Technical from './layouts/Technical';
import TermsConditions from './layouts/TermsConditions';
import PrivacyPolicy from './layouts/PrivacyPolicy';
import Contact from './layouts/Contact';
import Shipyard from './layouts/Shipyard';
import PhotoGallery from './layouts/PhotoGallery';
import NewsEvent from './layouts/NewsEvent';
import NewsDetail from './layouts/NewsDetail';

import './styles/App.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


//import { AppConsumer } from './lib/Store/Store';

const App = () => {
  return (
    <div className="App">
      <Store>
        <Router>
          <Switch>
            <Route path="/news-detail" component={NewsDetail} />
            <Route path="/news-event" component={NewsEvent} />
            <Route path="/photo-gallery" component={PhotoGallery} />
            <Route path="/shipyard-service" component={Shipyard} />
            <Route path="/contact" component={Contact} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-conditions" component={TermsConditions} />
            <Route path="/technical-specs" component={Technical} />
            <Route path="/naval-architecture" component={NavalArchitecture} />
            <Route path="/blaundus-b30" component={BlaundusB30} />
            <Route path="/blaundus-b26" component={BlaundusB26} />
            <Route path="/newbuild" component={NewBuild} />
            <Route path="/about" component={About} />
            <Route path="/" component={Home} />
          </Switch>
        </Router>
      </Store>
    </div>
  );
};

export default App;

import React, { createContext, useContext, useReducer, useEffect } from 'react';
import * as actions from './actions';
import * as effects from './effects';
import { reducer } from './reducers';
const isServer = typeof window === 'undefined';

export const AppContext = createContext();
export const useStore = () => useContext(AppContext);
export const AppProvider = ({ value, children }) => (
  <AppContext.Provider value={value}>{children}</AppContext.Provider>
);

export const initialState = {
  appMenuIsActive: false
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  const appDispatch = async action => {
    console.log('appDispatch:', action);

    let dispatchResult;

    switch (action.type) {
      case actions.SET_STATE:
        dispatch(actions.setState(action.newState));
        break;

      case actions.TOGGLE_APP_MENU:
        dispatch(actions.setState({ appMenuIsActive: !state.appMenuIsActive }));

        break;

      default:
        break;
    }

    return dispatchResult ? dispatchResult : false;
  };

  return <AppProvider value={[state, appDispatch]}>{children}</AppProvider>;
};

export default Store;

import React, { useState, useEffect } from 'react';
import { useStore } from '../../lib/Store/Store';
import MenuImage from '../../assets/images/menu-left-image.png';
import LinkToExternal from '../../components/LinkToExternal';
import Facebook from '../../assets/images/facebook.svg';
import Twitter from '../../assets/images/twitter.svg';
import Instagram from '../../assets/images/instagram.svg';
import Youtube from '../../assets/images/youtube.svg';
import Linkedin from '../../assets/images/linkedin.svg';
import './AppNavMenu.scss';
import { Link } from 'react-router-dom';

const AppNavMenu = () => {
  const [state, dispatch] = useStore();
  const className = state.appMenuIsActive ? ' is-active' : '';

  return (
    <div className={'AppNavMenu' + className}>
      <div className="MenuContainer">
        <div className="MenuBody">
          <img className="MenuImage d-none d-md-block" src={MenuImage} alt="" />
          <ul className="nav flex-column flex-nowrap overflow-hidden">
            <li className="nav-item">
              <a className="nav-link" href="/about">
                <span className="MenuText">ABOUT US / PHILOSOPHY</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/naval-architecture">
                <span className="MenuText">NAVAL ARCHITECTURE & DESIGN</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/technical-specs">
                <span className="MenuText">SPECIFICATION & LAYOUT</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/shipyard-service">
                <span className="MenuText">THE SHIPYARD & SERVICE</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/newbuild">
                <span className="MenuText">NEW BUILD & FLEET</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/photo-gallery">
                <span className="MenuText">GALLERY</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <span className="MenuText">NEWS & EVENTS</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">
                <span className="MenuText">CONTACT & CAREERS</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="MenuFooter">
          <div className="d-flex justify-content-between">
            <LinkToExternal
              href="https://www.facebook.com/blaundusyachts/"
              icon={Facebook}
              iconAlt="Blaundus Facebook Page"
            />
            <LinkToExternal
              href="https://twitter.com/blaundusyachts"
              icon={Twitter}
              iconAlt="Blaundus Twitter Page"
            />
            <LinkToExternal
              href="https://www.instagram.com/Blaundus.Yachts/"
              icon={Instagram}
              iconAlt="Blaundus Instagram Page"
            />
            <LinkToExternal
              href="https://www.youtube.com/channel/UChmxwjzGAlzIQZkhyI51VIg"
              icon={Youtube}
              iconAlt="Blaundus Youtube Page"
            />
            <LinkToExternal
              href="https://www.linkedin.com/company/blaundusyachts/"
              icon={Linkedin}
              iconAlt="Blaundus Linkedin Page"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppNavMenu;

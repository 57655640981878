import React from 'react';
import HomeSlider from '../../components/HomeSlider/HomeSlider';
import Page from '../Page/Page';
import './Home.scss';
import HomeBeta from '../HomeBeta';

const Home = () => {
  return <HomeBeta />
  //return <HomeSlider />
};

export default Home;

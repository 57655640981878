import React from 'react';
import './HeroBanner.scss';
import { Link } from 'react-router-dom';
import HeroInner from './HeroInner';

const HeroBanner = ({ image, name, message, linked }) => {
  return (
    <div className="hero-banner">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {linked ? (
              <Link to={`blaundus-b${name}`}>
                <HeroInner
                  image={image}
                  name={name}
                  message={message}
                ></HeroInner>
              </Link>
            ) : (
              <HeroInner
                image={image}
                name={name}
                message={message}
              ></HeroInner>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;

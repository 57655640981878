import React, { Component } from 'react';
import './SequenceViewer.scss';

const pixelsPerDegree = 3;

class SequenceViewer extends Component {
  state = {
    dragging: false,
    imageIndex: 0,
    dragStartIndex: 0,
  };

  componentDidMount = () => {
    document.addEventListener('mousemove', this.handleMouseMove, false);
    document.addEventListener('mouseup', this.handleMouseUp, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousemove', this.handleMouseMove, false);
    document.removeEventListener('mouseup', this.handleMouseUp, false);
  };

  handleMouseDown = e => {
    e.persist();
    this.setState(state => ({
      dragging: true,
      dragStart: e.screenX,
      dragStartIndex: state.imageIndex
    }));
    this.props.hideText();
  };

  handleMouseUp = () => {
    this.setState({ dragging: false });
  };

  updateImageIndex = currentPosition => {
    let numImages = this.props.numImages;
    const pixelsPerImage = pixelsPerDegree * (360 / numImages);
    const { dragStart, imageIndex, dragStartIndex } = this.state;
    // pixels moved
    let dx = (currentPosition - dragStart) / pixelsPerImage;
    let index = Math.floor(dx) % numImages;

    if (index < 0) {
      index = numImages + index - 1;
    }
    index = (index + dragStartIndex) % numImages;
    if (index !== imageIndex) {
      this.setState({ imageIndex: index });
    }
  };

  handleMouseMove = e => {
    if (this.state.dragging) {
      this.updateImageIndex(e.screenX);
    }
  };

  preventDragHandler = e => {
    e.preventDefault();
  };

  videoControls = () => {
    if (this.video) {
      this.video.currentTime = this.state.imageIndex / this.props.frameRate;
    }
    return (
      <video ref={ref => (this.video = ref)} key={this.props.videoSrc}>
        <source
          src={this.props.videoSrc}
          type="video/mp4"
        />
      </video>
    );
  };

  renderImage = () => {
    return (
      <div className="slider-video" style={{ height: this.props.mobile100vh }}>
        {this.videoControls()}
      </div>
    );
  };

  render = () => {
    return (
      <div
        className="slider-video-wrapper react-360-img"
        style={{ height: this.props.mobile100vh }}
        onMouseDown={this.handleMouseDown}
        onDragStart={this.preventDragHandler}
      >
        {this.renderImage()}
      </div>
    );
  };
}

export default SequenceViewer;

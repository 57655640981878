import React from 'react';
import InnerPage from '../InnerPageWrapper';
import './Contact.scss';
import Tuzla from '../../assets/images/tuzla.png';
import Headquarters from '../../assets/images/headquarters.png';
import GetInTouch from '../../assets/images/get_in_touch.png';

const Contact = () => {
  return (
    <InnerPage>
      <div className="contact-page">
        <div className="">
          <div className="container-fluid contact-wrapper">
            <div className="row flex-column-reverse flex-md-row">
              <div className="col-xl-6 d-flex flex-column justify-content-end contact-text">
                <div className="row">
                  <div className="col-12">
                    <h2 className="contact-title">Headquarters</h2>
                  </div>
                  <div className="col-12">
                    <p className="contact-description">
                      If you have any questions or would like to know more about
                      our yachts or services, please do not hesitate to contact
                      us. You can find the contact information of our HQ and
                      shipyard below.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="contact-header">Address</div>
                    <div className="contact-details">
                      Burhaniye Mah. Kagitcibasi Sok.
                      <br /> No:14 Narlikosk Uskudar/Istanbul
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="contact-header">Telephone & Fax</div>
                    <div className="contact-details">
                      ( +90 ) 444 1 544 - ( +90 ) 216 651 00
                      <br />( 34-35-36-37-38 )
                      <br />( +90 ) 216 651 51 39 ( FAX )
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <img src={Headquarters} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="has-dark-background">
          <div className="container-fluid contact-wrapper">
            <div className="row flex-column-reverse flex-md-row">
              <div className="col-xl-6 d-flex flex-column justify-content-end contact-text">
                <div className="row">
                  <div className="col-12">
                    <h2 className="contact-title">Tuzla Shipyard</h2>
                  </div>
                  <div className="col-12">
                    <p className="contact-description">
                      If you have any questions or would like to know more about
                      our yachts or services, please do not hesitate to contact
                      us. You can find the contact information of our HQ and
                      shipyard below.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="contact-header">Address</div>
                    <div className="contact-details">
                      Guzelyali Mah. Sahilyolu Cad. Yakut Sok.
                      <br /> No:24 D:4 Pendik/Istanbul
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="contact-header">Telephone & Fax</div>
                    <div className="contact-details">
                      ( +90 ) 444 1 544 - ( +90 ) 216 651 00
                      <br />( 34-35-36-37-38 )
                      <br />( +90 ) 216 651 51 39 ( FAX )
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <img src={Tuzla} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="has-gray-background">
          <div className="container-fluid contact-wrapper">
            <div className="row flex-column-reverse flex-md-row">
              <div className="col-xl-6 d-flex flex-column justify-content-end contact-text">
                <div className="row">
                  <div className="col-12">
                    <h2 className="contact-title">Work with us</h2>
                  </div>
                  <div className="col-12">
                    <p className="contact-description">
                      We would be happy to welcome potential candidates who are
                      passionate about sailing, who could add value to our brand
                      with their knowledge, and who strive to deliver the
                      performance possible.
                    </p>
                    <p className="contact-description">
                      You can find job postings for all of our open positions on
                      our LinkedIn page—please follow us there. You can also
                      send your CV to our e-mail address info@blaundus.com
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <img src={GetInTouch} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </InnerPage>
  );
};

export default Contact;

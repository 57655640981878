import React, { Component } from 'react';
import './ThreeSixty.scss';
import TsixtyIcon from '../../assets/images/three-sixty-icon.svg';
import Plus from '../../assets/images/plus-icon.svg';
import MobileBackground from '../../assets/images/mobile_360.jpg';
import InteriorIco from '../../assets/images/btn-ico-interior.svg';
import ExteriorIco from '../../assets/images/btn-ico-exterior.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import SequenceViewer from '../SequenceViewer';
import Page from '../Page/Page';
import Vimeo from '@u-wave/react-vimeo';

class ThreeSixty extends Component {
  state = {
    exteriorActive: false,
    textHidden: false,
    videoOpen: false,
    videoSrc: ''
  };

  hideText = () => {
    !this.state.textHidden && this.setState({ textHidden: true });
  };

  render() {
    const { exteriorActive } = this.state;
    return (
      <Page>
        {window.innerWidth > 768 ? (
          <div
            className="three-sixty-wrapper"
            style={{ height: this.props.mobile100vh }}
          >
            <div
              className={`three-sixty-inner ${this.state.textHidden &&
                'd-none'}`}
            >
              <div>
                <div className="ts-icon d-flex align-items-center">
                  <img src={TsixtyIcon} />
                  <span>360&#176;</span>
                </div>
                <h2>Now It's your turn</h2>
                <p>Drag mouse to navigate</p>
              </div>
            </div>
            <div className="slider-video-button">
              <button
                className="alpha"
                onClick={() =>
                  !exteriorActive
                    ? this.setState({ exteriorActive: true })
                    : this.setState({ exteriorActive: false })
                }
              >
                <span>
                  360&#176; {!exteriorActive ? 'Exterior' : 'Interior'}
                </span>
                <img src={Plus} alt="plus icon" />
              </button>
            </div>
            {!exteriorActive ? (
              <SequenceViewer
                videoSrc="http://blaundusyachts.com/videos/interior_360.mp4"
                numImages={101}
                hideText={this.hideText}
                frameRate={10}
                mobile100vh={this.props.mobile100vh}
              />
            ) : (
              <SequenceViewer
                videoSrc="http://blaundusyachts.com/videos/exterior_360.mp4"
                numImages={123}
                hideText={this.hideText}
                frameRate={10}
                mobile100vh={this.props.mobile100vh}
              />
            )}
          </div>
        ) : (
          <div
            className="has-background-image"
            style={{
              height: this.props.mobile100vh,
              backgroundImage: `url(${MobileBackground})`
            }}
          >
            <div className="three-sixty-wrapper-mobile d-flex flex-column align-items-start justify-content-end h-100">
              <img
                className="mobile-360-image"
                src={TsixtyIcon}
                alt="360 video icon"
              />
              <h2>360&#176; VIDEO</h2>
              <p>Press the button to watch video</p>
              <button
                className="btn-video btn-interior-video mb-2"
                onClick={() =>
                  this.setState({ videoOpen: true, videoSrc: 398007065 })
                }
              >
                <span>360&#176; VIDEO INTERIOR VIDEO</span>
                <img src={InteriorIco} alt="play interior video" />
              </button>
              <button
                className="btn-video btn-exterior-video"
                onClick={() =>
                  this.setState({ videoOpen: true, videoSrc: 396698164 })
                }
              >
                <span>360&#176; VIDEO EXTERIOR VIDEO</span>
                <img src={ExteriorIco} alt="play exterior video" />
              </button>
            </div>
            {this.state.videoOpen && (
              <div
                className="mobile-360-video"
                style={{
                  height: this.props.mobile100vh
                }}
              >
                <button className="close-mobile-video">
                  <img
                    src={CloseIcon}
                    alt="close mobile video"
                    onClick={() => this.setState({ videoOpen: false })}
                  />
                </button>
                <Vimeo
                  video={this.state.videoSrc}
                  showTitle={false}
                  width={window.innerWidth}
                  muted={true}
                  autoplay={true}
                />
              </div>
            )}
            <div
              className="three-sixty-wrapper-mobile-overlay"
              style={{
                height: this.props.mobile100vh
              }}
            ></div>
          </div>
        )}
      </Page>
    );
  }
}

export default ThreeSixty;

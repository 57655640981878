import React, { useEffect } from 'react';
import './NavalSneak.scss';
import SketchImage from '../../assets/images/naval-sketch.svg';

const NavalSneak = props => {
  useEffect(() => {
    console.log(props);

    let timer1 = setTimeout(() => props.sendData && props.sendData(), 4000);

    return () => {
      clearTimeout(timer1);
    };
  });

  return (
    <div
      className="naval-sneak-wrapper content-padding-right"
      style={{ backgroundImage: `url(${SketchImage})` }}
    >
      <div className="row">
        <div className="col-12">
          <h2>beginning Of design</h2>
          <p>
            Every corner of this yacht has been designed with only one thing on
            our mind: YOU!&nbsp;
            <span className="d-none d-md-inline">
              We put ourselves in your shoes and think about how you could make
              the best of your time on your yacht.
            </span>
          </p>
        </div>
        <div className="col-12 d-none d-md-block">
          <img
            src={SketchImage}
            className="img-fluid sketch"
            alt="blaundus sketch"
          />
        </div>
      </div>
    </div>
  );
};

export default NavalSneak;

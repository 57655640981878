import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './InteriorSneak.scss';
import S1 from '../../assets/images/interior-sneak-1.jpg';
import S2 from '../../assets/images/interior-sneak-2.jpg';
import Page from '../Page/Page';
import HorizontalSlider from '../../components/HorizontalSlider';
import { naval_interior_modern, naval_interior_classic } from '../../siteData';
import BckgSlider from '../../components/BckgSlider';

const InteriorSneak = () => {
  const [sliderContent, setSliderContent] = useState(naval_interior_modern);
  const interiorGalleryRef = useRef(null);

  const innerHeight = () => {
    if (window.innerWidth < 899) {
      return window.innerHeight;
    }
  };

  return (
    <Page>
      <div className="interior-sneak-wrapper">
        <div className="row align-items-end">
          <div className="col-lg-8">
            <h2>Interior Design</h2>
            <p>
              The priority of Blaundus, first and foremost, is customer
              satisfaction. Accordingly, we adopt “semi-custom design” approach
              four our products to offer our customers the opportunity to design
              the interior of their yachts freely and inventively.
            </p>
          </div>
          <div className="col-lg-4">
            <div className="slider-filter">
              <button
                className={
                  sliderContent === naval_interior_modern ? 'active-filter' : ''
                }
                onClick={() => {
                  setSliderContent(naval_interior_modern);
                  console.log(interiorGalleryRef.current);
                }}
              >
                modern
              </button>
              <span></span>
              <button
                className={
                  sliderContent === naval_interior_classic
                    ? 'active-filter'
                    : ''
                }
                onClick={() => {
                  setSliderContent(naval_interior_classic);
                }}
              >
                classic
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="interior-gallery-wrapper">
              <BckgSlider
                sliderData={sliderContent}
                className="interior-sneak-gallery"
                centerMode={true}
                overflowWidth="150"
                slidesToShow={1}
                mobile100vh={innerHeight()}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default InteriorSneak;

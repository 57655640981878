import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './PhotoGallery.scss';
import CloseIcon from '../../assets/images/close-icon.svg';
import GalleryArrow from '../../assets/images/gallery-arrow.svg';
import HorizontalSlider from '../../components/HorizontalSlider';
import {
  b30_exterior,
  naval_interior_modern,
  naval_interior_classic
} from '../../siteData';
import BckgSlider from '../../components/BckgSlider';

class PhotoGallery extends Component {
  state = {
    activeType: 'interior',
    sliderContent: naval_interior_modern
  };

  setInterior = () => {
    this.setState({ activeType: 'interior' });
  };

  setExterior = () => {
    this.setState({ activeType: 'exterior' });
  };

  innerHeight = () => {
    if (window.innerWidth < 899) {
      return window.innerHeight;
    }
  };

  render() {
    const { activeType, sliderContent } = this.state;
    return (
      <div className="gallery-wrapper" style={{ height: this.innerHeight() }}>
        <div className="gallery-types">
          <button
            onClick={this.setInterior}
            className={`gallery-type-selector ${
              activeType === 'interior' ? 'active-type' : ''
            }`}
          >
            <span>interior</span>
          </button>
          <button
            onClick={this.setExterior}
            className={`gallery-type-selector ${
              activeType === 'exterior' ? 'active-type' : ''
            }`}
          >
            <span>exterior</span>
          </button>
        </div>
        <div className="gallery-inner">
          <div className="gallery-header">
            <div className="gallery-title">
              Blaundus b<span>30</span>
            </div>
            <Link to="/">
              <button className="btn-icon-only">
                <img src={CloseIcon} alt="close specs main" />
              </button>
            </Link>
          </div>
          <div className="gallery-body">
            {activeType === 'interior' ? (
              <BckgSlider
                sliderData={sliderContent}
                className="gallery-page"
                centerMode={true}
                overflowWidth="100"
                slidesToShow={1}
                showCount={true}
                customArrow={GalleryArrow}
                mobile100vh={this.innerHeight()}
              />
            ) : (
              <BckgSlider
                sliderData={b30_exterior}
                className="gallery-page"
                centerMode={true}
                overflowWidth="100"
                slidesToShow={1}
                showCount={true}
                customArrow={GalleryArrow}
                mobile100vh={this.innerHeight()}
              />
            )}

            {activeType === 'interior' && (
              <div className="slider-filter d-none d-md-block">
                <button
                  className={
                    sliderContent === naval_interior_modern
                      ? 'active-filter'
                      : ''
                  }
                  onClick={() => {
                    this.setState({ sliderContent: naval_interior_modern });
                  }}
                >
                  modern
                </button>
                <span></span>
                <button
                  className={
                    sliderContent === naval_interior_classic
                      ? 'active-filter'
                      : ''
                  }
                  onClick={() => {
                    this.setState({ sliderContent: naval_interior_classic });
                  }}
                >
                  classic
                </button>
              </div>
            )}
            {activeType === 'interior' && (
              <div className="slider-filter filter-mobile d-flex justify-content-end d-md-none">
                <button
                  className={
                    sliderContent === naval_interior_modern
                      ? 'active-filter'
                      : ''
                  }
                  onClick={() => {
                    this.setState({ sliderContent: naval_interior_modern });
                  }}
                >
                  modern
                </button>
                <span></span>
                <button
                  className={
                    sliderContent === naval_interior_classic
                      ? 'active-filter'
                      : ''
                  }
                  onClick={() => {
                    this.setState({ sliderContent: naval_interior_classic });
                  }}
                >
                  classic
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PhotoGallery;

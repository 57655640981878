import React, { Component } from 'react';
import ReactPlayer from 'react-player';

/* class VideoElement extends Component {
  state = {
    url: this.props.videoSrc,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  };

  load = url => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false
    });
  };

  handleStop = () => {
    this.setState({ playing: false });
  };

  handleStart = () => {
    this.setState({ playing: true });
  };

  handleDuration = duration => {
    console.log('onDuration', duration);
    this.setState({ duration });
  };

  handleSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  ref = player => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip
    } = this.state;
    return (
      <div>
        <div className="player-wrapper">
          <ReactPlayer
            ref={this.ref}
            className="react-player"
            url={url}
            playing={playing}
            width="100%"
            height="100%"
            loop={loop}
            muted
            onEnded={() => {
              console.log('ended');
              this.handleStop();
            }}
            onBuffer={() => console.log('onBuffer')}
            onReady={() => {
              this.handleStart();
            }}
            onDuration={this.handleDuration}
          />
          {console.log(played)}
        </div>
      </div>
    );
  }
} */

class VideoElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      videoEnded: false
    };
  }

  componentDidMount() {
    if (this.video) {
      this.video.addEventListener('loadeddata', () => {
        this.setState({ loading: false });
        //console.log('video start');
        //document.body.classList.add('disable-wheel');
      });

      this.video.addEventListener('ended', () => {
        this.setState({ videoEnded: true });
        //this.props.sendData && this.props.sendData();
        console.log('ended ', this.state.videoEnded);
        //document.body.classList.remove('disable-wheel');
      });
    }
  }

  /* componentDidUpdate(prevProps) {
    if (prevProps.videoSrc !== this.props.videoSrc) {
      console.log(this.props.videoSrc);
    }
  } */

  /* componentWillUnmount() {
    if (this.video) {
      this.video.removeEventListener('loadeddata', () => {});
    }
  } */

  render() {
    return (
      <div className="slider-video-wrapper">
        <video
          className="slider-video"
          ref={ref => (this.video = ref)}
          //poster={this.props.poster}
          autoPlay={true}
          muted
          style={
            {
              //opacity: this.state.loading ? 0 : 1,
              //transition: 'opacity, 2s ease-in-out'
            }
          }
        >
          <source src={this.props.videoSrc} type="video/mp4" />
        </video>
      </div>
    );
  }
}

export default VideoElement;

import React, { Component } from 'react';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import Experience from '../../components/HomeParts/Experience';
import Material from '../../components/HomeParts/Material';
import './HomeBeta.scss';
import TechnicalSneak from '../TechnicalSneak';
import Naval from '../../components/HomeParts/Naval';
import Exterior from '../../components/HomeParts/Exterior';
import Features from '../../components/HomeParts/Features';
import FieldFeatures from '../../components/HomeParts/FieldFeatures';
import Interior from '../../components/HomeParts/Interior';
import SequenceViewer from '../SequenceViewer';
import InteriorSneak from '../InteriorSneak';
import BeachClub from '../BeachClub';
import ArrowDown from '../../assets/images/arrow-down.svg';
import ActiveComponent from '../../components/ActiveComponent';
import ThreeSixty from '../ThreeSixty';
import ShipyardSneak from '../ShipyardSneak';

class HomeBeta extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      components: [
        'experience',
        'material',
        'exterior',
        'features',
        'field',
        'beach',
        'interior',
        'sequenceViewer',
        'interiorDesign'
      ],
      currentIndex: 0,
      textLight: false,
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    console.log('state', this.context);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      console.log(this.state.currentIndex);
      if (prevState.currentIndex < this.state.currentIndex) {
        console.log('next item');
      } else {
        console.log('prev item');
      }
    }
  }

  setActiveByIndex = val => {
    val < 8 && this.setState({ currentIndex: val + 1 });
  };

  nextIndex = () => {
    const { components, currentIndex } = this.state;
    if (currentIndex == components.length - 1) {
      return this.setState({ currentIndex: 0 });
    }

    return this.setState({
      currentIndex: currentIndex + 1
    });
  };

  prevIndex = () => {
    const { components, currentIndex } = this.state;
    if (currentIndex == 0) {
      return this.setState({
        currentIndex: components.length - 1
      });
    }

    return this.setState({
      currentIndex: currentIndex - 1
    });
  };

  innerHeight = () => {
    if (window.innerWidth < 899) {
      return window.innerHeight;
    }
  };

  render() {
    const { textLight, currentIndex } = this.state;

    return (
      <div>
        <ReactScrollWheelHandler
          upHandler={this.prevIndex}
          downHandler={this.nextIndex}
          pauseListeners={currentIndex === 8 ? true : false}
        >
          <div className="home-menu">
            <ul>
              <li className={currentIndex === 0 ? 'active-element' : ''}>
                <button
                  onClick={() => {
                    this.setState({ currentIndex: 0 });
                  }}
                >
                  <span>Start Experience</span>
                </button>
              </li>
              <li
                className={`${
                  currentIndex === 1 ? 'active-element text-light' : ''
                }`}
              >
                <button
                  onClick={() => {
                    this.setState({
                      currentIndex: 1
                    });
                  }}
                >
                  <span>Material</span>
                </button>
              </li>
              <li className={currentIndex === 2 ? 'active-element' : ''}>
                <button
                  onClick={() => {
                    this.setState({ currentIndex: 2 });
                  }}
                >
                  <span>Exterior</span>
                </button>
              </li>
              <li className={currentIndex === 3 ? 'active-element' : ''}>
                <button
                  onClick={() => {
                    this.setState({ currentIndex: 3 });
                  }}
                >
                  <span>Features</span>
                </button>
              </li>
              <li className={currentIndex === 4 ? 'active-element' : ''}>
                <button
                  onClick={() => {
                    this.setState({ currentIndex: 4 });
                  }}
                >
                  <span>Field Features</span>
                </button>
              </li>
              <li className={currentIndex === 5 ? 'active-element' : ''}>
                <button
                  onClick={() => {
                    this.setState({ currentIndex: 5 });
                  }}
                >
                  <span>Beach Club</span>
                </button>
              </li>
              <li className={currentIndex === 6 ? 'active-element' : ''}>
                <button
                  onClick={() => {
                    this.setState({ currentIndex: 6 });
                  }}
                >
                  <span>Interior</span>
                </button>
              </li>
              <li className={currentIndex === 7 ? 'active-element' : ''}>
                <button
                  onClick={() => {
                    this.setState({ currentIndex: 7 });
                  }}
                >
                  <span>360 viewer</span>
                </button>
              </li>
              <li className={currentIndex === 8 ? 'active-element' : ''}>
                <button
                  onClick={() => {
                    this.setState({ currentIndex: 8 });
                  }}
                >
                  <span>Interior Design</span>
                </button>
              </li>
            </ul>
          </div>
          <div className="components-wrapper">
            <div
              className={
                currentIndex === 8 || currentIndex === 9
                  ? 'd-none'
                  : 'components-overlay'
              }
            ></div>
            {currentIndex === 0 && (
              <Experience mobile100vh={this.innerHeight()} />
            )}
            {currentIndex === 1 && (
              <Material mobile100vh={this.innerHeight()} />
            )}
            {currentIndex === 2 && (
              <Exterior mobile100vh={this.innerHeight()} />
            )}
            {currentIndex === 3 && (
              <Features mobile100vh={this.innerHeight()} />
            )}
            {currentIndex === 4 && (
              <FieldFeatures mobile100vh={this.innerHeight()} />
            )}
            {currentIndex === 5 && (
              <BeachClub mobile100vh={this.innerHeight()} />
            )}
            {currentIndex === 6 && (
              <Interior mobile100vh={this.innerHeight()} />
            )}
            {currentIndex === 7 && (
              <ThreeSixty mobile100vh={this.innerHeight()} />
            )}
            {currentIndex === 8 && (
              <InteriorSneak mobile100vh={this.innerHeight()} />
            )}
          </div>
          <div className="slider-next-button-wrapper">
            <button
              className="slider-next-button"
              onClick={() => {
                this.nextIndex();
              }}
            >
              <img src={ArrowDown} />
              Next
            </button>
          </div>
        </ReactScrollWheelHandler>
      </div>
    );
  }
}

export default HomeBeta;

import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import Slider from 'react-slick';
import FatihAydin1 from '../../assets/images/fatih-aydin.png';
import EmrecanOzgun from '../../assets/images/emrecan-ozgun.png';
import ErolYuksel from '../../assets/images/erol-yuksel.png';
import TuncaySenyurt from '../../assets/images/tuncay-senyurt.png';
import Interview from '../Interview';

class AsNavFor extends Component {
  state = {
    nav1: null,
    nav2: null,
    videoSrc: null
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  playVideo = src => {
    console.log(this.childRef);
  };

  render() {
    const responsivitySettings = {
      className: 'about-slider-thumbs',
      responsive: [
        {
          breakpoint: 899,
          settings: {
            centerMode: true,
            arrows: false,
            slidesToShow: 2
          }
        }
      ]
    };

    const playerSettings = {
      beforeChange: (current, next) => {
         let currentRef = eval(`this.childRef${next}`);
        let refToStop = eval(`this.childRef${current}`);

        refToStop.video.pause();
        refToStop.video.currentTime = 0;
        refToStop.setState({ playIcon: true });
      }
    };

    return (
      <>
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          className="about-slider"
          fade={true}
          {...playerSettings}
        >
          <div className="about-item">
            <div className="d-flex flex-column flex-md-row">
              <div className="item-text">
                <h3 lang="tr">Fatih Aydın</h3>
                <h4>Chairman of Board</h4>
              </div>
              <div className="item-media">
                <Interview
                  videoSrc="http://www.blaundusyachts.com/videos/fatih_aydin.mp4"
                  ref={ref => (this.childRef0 = ref)}
                  poster={FatihAydin1}
                />
                {/* <Vimeo video="396687623" /> */}
              </div>
            </div>
          </div>
          <div className="about-item">
            <div className="d-flex flex-column flex-md-row">
              <div className="item-text">
                <h3 lang="tr">Emrecan Özgün</h3>
                <h4>Designer</h4>
              </div>
              <div className="item-media">
                <Interview
                  videoSrc="http://www.blaundusyachts.com/videos/emrecan_ozgun.mp4"
                  ref={ref => (this.childRef1 = ref)}
                  poster={EmrecanOzgun}
                />
                {/* <Vimeo video="396686687" /> */}
              </div>
            </div>
          </div>
          <div className="about-item">
            <div className="d-flex flex-column flex-md-row">
              <div className="item-text">
                <h3 lang="tr">Erol Yüksel</h3>
                <h4>CEO of Blaundus Yachting</h4>
              </div>
              <div className="item-media">
                <Interview
                  videoSrc="http://www.blaundusyachts.com/videos/erol_yuksel.mp4"
                  ref={ref => (this.childRef2 = ref)}
                  poster={ErolYuksel}
                />
                {/* <Vimeo video="396685996" /> */}
              </div>
            </div>
          </div>
          <div className="about-item">
            <div className="d-flex flex-column flex-md-row">
              <div className="item-text">
                <h3 lang="tr">Tuncay Şenyurt</h3>
                <h4>Naval Architect & Marine Engineer</h4>
              </div>
              <div className="item-media">
                <Interview
                  videoSrc="http://www.blaundusyachts.com/videos/tuncay_senyurt.mp4"
                  ref={ref => (this.childRef3 = ref)}
                  poster={TuncaySenyurt}
                />
                {/*  <Vimeo video="396687976" /> */}
              </div>
            </div>
          </div>
        </Slider>
        <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={4}
          swipeToSlide={true}
          focusOnSelect={true}
          centerMode={true}
          centerPadding="30px"
          {...responsivitySettings}
        >
          <div className="thumb-item">
            <div className="d-flex justify-content-end flex-column h-100">
              <h3>Fatih Aydın</h3>
              <p>Chairman of the board</p>
              <div className="thumb-overlay"></div>
              <div
                className="has-background-image"
                style={{ backgroundImage: `url(${FatihAydin1})` }}
              ></div>
            </div>
          </div>
          <div className="thumb-item">
            <div className="d-flex justify-content-end flex-column h-100">
              <h3>Emrecan Özgün</h3>
              <p>Designer</p>
              <div className="thumb-overlay"></div>
              <div
                className="has-background-image"
                style={{ backgroundImage: `url(${EmrecanOzgun})` }}
              ></div>
            </div>
          </div>
          <div className="thumb-item">
            <div className="d-flex justify-content-end flex-column h-100">
              <h3>Erol Yüksel</h3>
              <p>Naval Architecture</p>
              <div className="thumb-overlay"></div>
              <div
                className="has-background-image"
                style={{ backgroundImage: `url(${ErolYuksel})` }}
              ></div>
            </div>
          </div>
          <div className="thumb-item">
            <div className="d-flex justify-content-end flex-column h-100">
              <h3 lang="tr">Tuncay Şenyurt</h3>
              <p>Naval Architect & Marine Engineer</p>
              <div className="thumb-overlay"></div>
              <div
                className="has-background-image"
                style={{ backgroundImage: `url(${TuncaySenyurt})` }}
              ></div>
            </div>
          </div>
        </Slider>
      </>
    );
  }
}

export default AsNavFor;

import React from 'react';
import AppNavMenu from '../AppNavMenu/AppNavMenu';
import TopNavMenu from '../TopNavMenu/TopNavMenu';
import './Page.scss';

const Page = ({ children, colorStyle }) => {
  const innerHeight = () => {
    if (window.innerWidth < 899) {
      return window.innerHeight;
    }
  };
  return (
    <div className="Page" style={{ height: innerHeight() }}>
      <TopNavMenu colorStyle={colorStyle} homePageMenu={true} />
      <AppNavMenu />
      {children}
    </div>
  );
};

export default Page;

import React, { Component } from 'react';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ArrowNext from '../../assets/images/arrowNext.svg';
import ArrowPrev from '../../assets/images/arrowPrev.svg';
import ImgZoom from '../../assets/images/img-zoom-icon.svg';

class BckgSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      currentSlide: 1,
      slideIndex: 0,
      isOpen: false,
      currentImg: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.slider.slickGoTo(0);
    }
  }

  handleClickImage = (e, image) => {
    e && e.persist();

    this.setState({
      currentImg: image
    });
  };

  handleCloseModal = e => {
    e && e.persist();

    this.setState({
      currentImg: ''
    });
  };

  render() {
    const {
      className,
      overflowWidth,
      centerMode,
      slidesToShow,
      sliderData,
      infinite,
      showCount,
      customArrow
    } = this.props;

    const { photoIndex, isOpen } = this.state;

    const images = Object.keys(sliderData).map(iKey => sliderData[iKey].image);

    const NextArrow = props => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, right: overflowWidth * 2 + 20 || '0' }}
          onClick={onClick}
        >
          <img src={customArrow || ArrowNext} alt="slider-next" />
        </div>
      );
    };

    const PrevArrow = props => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, right: overflowWidth * 2 + 65 || '0' }}
          onClick={onClick}
        >
          <img src={customArrow || ArrowPrev} alt="slider-prev" />
        </div>
      );
    };

    const settings = {
      className: className || '',
      dots: false,
      arrows: true,
      lazyLoad: true,
      infinite: infinite || false,
      slidesToShow: slidesToShow || 1,
      centerMode: centerMode || false,
      centerPadding: overflowWidth || '0',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      afterChange: current =>
        this.setState({ currentSlide: current + 1, photoIndex: current }),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: true,
            arrows: false,
            slidesToShow: 1,
            centerPadding: 20
          }
        }
      ]
    };

    return (
      <>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {sliderData &&
            Object.keys(sliderData).map(idx => (
              <div key={idx}>
                <div className="slider-item">
                  {sliderData[idx].title && (
                    <div
                      className="slider-title"
                      dangerouslySetInnerHTML={{
                        __html: sliderData[idx].title
                      }}
                    />
                  )}
                  <div
                    className="slider-image"
                    style={{
                      backgroundImage: `url(${sliderData[idx].image})`,
                      height:
                        this.props.mobile100vh && this.props.mobile100vh - 300
                    }}
                  ></div>
                  <button
                    className="img-zoom-button"
                    /* onClick={e =>
                      this.handleClickImage(e, sliderData[idx].image)
                    } */
                    onClick={() => this.setState({ isOpen: true })}
                  >
                    <img src={ImgZoom} alt="zoom" />
                  </button>
                </div>
              </div>
            ))}
        </Slider>
        {isOpen && (
          <Lightbox
            /* mainSrc={this.state.currentImg} */
            mainSrc={images[photoIndex]}
            onCloseRequest={() => {
              this.setState({ isOpen: false });
              this.slider.slickGoTo(photoIndex)
              
            }}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
        {showCount && (
          <div className="count-label d-none d-md-flex">
            <span className="current">{this.state.currentSlide}</span>
            <span className="separator">/</span>
            <span className="total">{sliderData.length}</span>
          </div>
        )}
      </>
    );
  }
}

export default BckgSlider;

import React from 'react';
import { Link } from 'react-router-dom';
import InnerPage from '../InnerPageWrapper';
import Blaundus30 from '../../assets/images/blaundus-yacht.png';
import HorizontalSlider from '../../components/HorizontalSlider';
import BckgSlider from '../../components/BckgSlider';
import { blaundus30Gallery, b30_exterior } from '../../siteData';
import './Blaundus.scss';

const BlaundusB30 = () => {
  return (
    <InnerPage colorStyle="secondary">
      <div className="content-padding-x has-dark-background">
        <div className="blaundus-banner">
          <div className="blaundus-banner-title">
            A world, <br /> you only know
          </div>
          <div className="blaundus-banner-image">
            <img src={Blaundus30} alt="Blaundus B26" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="content-padding-x">
        <div className="row">
          <div className="col-xl-6">
            <div className="title-blaundus">
              BLAUNDUS
              <span>
                B<span>30</span>
              </span>
            </div>
          </div>
          <div className="col-xl-6">
            <p className="text-blaundus">
              Every corner of this yacht has been designed with only one thing
              on our mind: YOU!
            </p>
          </div>
        </div>
      </div>
      <div className="blaundus-slider">
        <BckgSlider
          sliderData={b30_exterior}
          className="nav-bottom"
          overflowWidth="100"
          centerMode={true}
          slidesToShow={2}
          infinite={true}
        />
      </div>
      <div className="specs-header">
        <div className="content-padding-x">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <h3 className="title-specifications">Specifications</h3>
              <Link
                to="/technical-specs"
                className="btn btn-outline-secondary px-5 text-uppercase d-none d-md-flex"
              >
                Go to layout +
              </Link>

              <Link to="/technical-specs" className="to-layout-mobile d-flex d-md-none">
                Layout +
              </Link>
            </div>
          </div>
        </div>
        <div className="content-padding-left">
          <hr />
        </div>
      </div>
      <div className="content-padding-x blaundus-spec-table">
        <div className="row">
          <div className="col-xl-4">
            <div>
              <ul className="list-unstyled spec-list">
                <li className="list-header">Main Dimensions</li>
                <li className="list-item">
                  <span>Loa</span>
                  <span>30 M</span>
                </li>
                <li className="list-item">
                  <span>LWL</span>
                  <span>27,599 M AT full load</span>
                </li>
                <li className="list-item">
                  <span>B Max</span>
                  <span>7,035 M</span>
                </li>
                <li className="list-item">
                  <span>DEPTHAMIDSHIP</span>
                  <span>3,83 M</span>
                </li>
                <li className="list-item">
                  <span>DRAUGHT, ATFULLLOAD</span>
                  <span>2,25 M ABT AT FULL LOAD</span>
                </li>
                <li className="list-item">
                  <span>DISPLACEMENT, FULL LOAD</span>
                  <span>220 T ABT</span>
                </li>
                <li className="list-item">
                  <span>GROSS TONNAGE (EXPECTED) (GT)</span>
                  <span>290 T</span>
                </li>
                <li className="list-item">
                  <span>CABIN CONFIGURATION</span>
                  <span>1 VIP / 1 Owner (Master) / 4 Guests</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-4">
            <div>
              <ul className="list-unstyled spec-list">
                <li className="list-header">Tanks Capacity</li>
                <li className="list-item">
                  <span>FUEL OIL</span>
                  <span>27,059 m3</span>
                </li>
                <li className="list-item">
                  <span>FRESH WATER</span>
                  <span>66,490 m3</span>
                </li>
                <li className="list-item">
                  <span>GREY WATER</span>
                  <span>2,312 m3</span>
                </li>
                <li className="list-item">
                  <span>BLACK WATER</span>
                  <span>1,004 m3</span>
                </li>
                <li className="list-item">
                  <span>BILGE WATER</span>
                  <span>0,364 m3</span>
                </li>
                <li className="list-header mt-3">Propulsion</li>
                <li className="list-item">
                  <span>Propeller (Minumum Noise & Vibration)</span>
                  <span>CJR / UK</span>
                </li>
                <li className="list-item">
                  <span>MAINENGINES</span>
                  <span>MANN 2x D2676 LE245, Medium Duty</span>
                  <span>2x 478 kW (650 mhp) @ 2,100 rpm</span>
                  <span>
                    2x ZF 3350&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2x GEAR RATID 4:1
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-4">
            <div>
              <ul className="list-unstyled spec-list">
                <li className="list-header">Electrical Generating Plant</li>
                <li className="list-item">
                  <span>MAINGENSET</span>
                  <span>1pcs x 55kW + 1pcs x 45 kW Kohler</span>
                  <span>380 V/50 Hz / Three Phase</span>
                </li>
                <li className="list-header mt-3">Thrusters</li>
                <li className="list-item">
                  <span>
                    SP 420 (U37) HYDRAULIC / 39 HP
                    <br />
                    PRESSURE PULSES ON THE HULL
                  </span>
                  <span>420 kgf. (Minumum Noise & Vibration) </span>
                </li>
                <li className="list-item">
                  <span>(RELATED TO VIBRATIONS)</span>
                  <span>30 kW/ Norwgian</span>
                </li>
                <li className="list-header mt-3">Performances</li>
                <li className="list-item">
                  <span>MAXIMUM CONTINUOS SPEED (100%MCR)</span>
                  <span>13,5 KNOTS</span>
                </li>
                <li className="list-item">
                  <span>CRUISING SPEED</span>
                  <span>9 KNOTS</span>
                </li>
                <li className="list-item">
                  <span>RANGE AT CRUISING SPEED</span>
                  <span>4500 NM</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-padding-x naval-exterior">
        <div className="row">
          <div className="col-xl-12 title-exterior">exterior design</div>
        </div>
      </div>
      <div className="exterior-gallery-wrapper blaundus-single-image-slider">
        <BckgSlider
          sliderData={blaundus30Gallery}
          className="nav-bottom-right"
          centerMode={true}
          slidesToShow={1}
          overflowWidth="100"
        />
      </div>
    </InnerPage>
  );
};

export default BlaundusB30;

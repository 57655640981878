import React from 'react';
import TopNavMenu from '../TopNavMenu/TopNavMenu';
import AppNavMenu from '../AppNavMenu/AppNavMenu';
import './Header.scss';

const Header = ({ colorStyle, visibility, onTop, menuActive }) => {
  const renderMenu = () => {
    if (menuActive) {
      return <TopNavMenu />;
    } else
      return visibility && <TopNavMenu colorStyle={colorStyle} onTop={onTop} />;
  };

  return (
    <div className="header-wrapper">
      {renderMenu()}
      <AppNavMenu />
    </div>
  );
};

export default Header;

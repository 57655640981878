import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import PlayIcon from '../../assets/images/play-icon.svg';

class Interview extends Component {
  state = {
    playIcon: true
  };

  playVideo = () => {
    this.video.play();
    this.setState({ playIcon: false });
  };

  render() {
    return (
      <div key={this.props.videoSrc}>
        <button
          className={`video-play-icon ${
            !this.state.playIcon ? 'd-none' : 'd-block'
          }`}
          onClick={() => {
            this.playVideo();
          }}
        >
          <img src={PlayIcon} alt="play" />
        </button>
        <div
          className={`image-overlay ${
            !this.state.playIcon ? 'd-none' : 'd-block'
          }`}
        ></div>
        <div className="interview-video">
          <video
            ref={ref => (this.video = ref)}
            poster={this.props.poster}
            controls
          >
            <source src={this.props.videoSrc} type="video/mp4" />
          </video>
        </div>
      </div>
    );
  }
}

export default Interview;

import React, { useEffect } from 'react';
import InnerPage from '../InnerPageWrapper';
import './Shipyard.scss';
import ShipyardHero from '../../assets/images/service.png';
import ShipyardBig1 from '../../assets/images/shipyard-1.jpg';
import ShipyardBig2 from '../../assets/images/shipyard-2.jpg';
import ShipyardGallery1 from '../../assets/images/shipyard-gallery-1.jpg';
import ShipyardGallery2 from '../../assets/images/shipyard-gallery-2.jpg';
import ShipyardGallery3 from '../../assets/images/shipyard-gallery-3.jpg';
import ShipyardGallery4 from '../../assets/images/shipyard-gallery-4.jpg';
import RinaLogo from '../../assets/images/rina-logo.svg';
import IACSLogo from '../../assets/images/iacs-logo.svg';
import MartimeLogo from '../../assets/images/martime-logo.svg';
import Service1 from '../../assets/images/service-1.jpg';
import Service2 from '../../assets/images/service-2.jpg';
import Service3 from '../../assets/images/service-3.jpg';
import Service4 from '../../assets/images/service-4.jpg';
import Service5 from '../../assets/images/service-5.jpg';
import Service6 from '../../assets/images/service-6.jpg';
import Service7 from '../../assets/images/service-7.jpg';
import Service8 from '../../assets/images/service-8.jpg';
import Service9 from '../../assets/images/service-9.jpg';

const Shipyard = () => {
  const innerHeight = () => {
    if (window.innerWidth < 899) {
      return window.innerHeight;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <InnerPage colorStyle="secondary">
      <div className="shipyard-hero d-flex align-items-center">
        <div className="d-flex flex-column">
          <h2>
            The Shipyard
            <br /> & Service
          </h2>
          <p>
            Our division based in Tuzla, Istanbul continuously expands our
            creative horizon and the know-how in our sector regarding
            metal-framed, semi-custom, and custom luxury yacht construction with
            the B-30-32-36M Explorer line. The group’s headquarters and the
            shipyard facilities where we keep building super yachts are also
            located in the same area. Operating at a shipyard fit for yacht
            owners, Blaundus is committed to its original vision to “deliver
            yachts both aesthetically pleasing and capable of sailing in the
            ocean to fellow yachtsmen.
          </p>
          <div
            className="has-background-image"
            style={{
              backgroundImage: `url(${ShipyardHero})`,
              height: innerHeight()
            }}
          >
            <div
              className="image-overlay"
              style={{
                height: innerHeight()
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="content-padding-x content-padding-y">
        <h2 className="title-shipyard">The Shipyard</h2>
        <div className="container-fluid shipyard-media-items">
          <div className="row">
            <div className="col-lg-6 pr-lg-0">
              <img src={ShipyardBig1} className="img-fluid" />
            </div>
            <div className="col-lg-6 pr-lg-0">
              <p className="media-item-text-right">
                Since 2000, we have successfully built and furnished more than
                13 ships and yachts which are all between 12 to 120 meters. Our
                headcount usually fluctuates between 400 and 500 including our
                in-house engineering team and the shipyard workers.
              </p>
            </div>
          </div>
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-6 pl-lg-0">
              <p className="media-item-text-left">
                Our manufacturing area covers 5,000 5.000 m² and includes
                covered indoor divisions and workshops of 1,000 m² and a
                150m-long furnishing dock.
              </p>
            </div>
            <div className="col-lg-6 pl-lg-0">
              <img src={ShipyardBig2} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="shipyard-gallery d-none d-md-flex align-items-end">
        <img src={ShipyardGallery1} className="img-fluid" />
        <img src={ShipyardGallery2} className="img-fluid" />
        <img src={ShipyardGallery3} className="img-fluid" />
        <img src={ShipyardGallery4} className="img-fluid" />
      </div>
      <div className="shipyard-gallery-mobile d-flex d-lg-none content-padding-x">
        <div className="left-column d-flex w-50 flex-column">
          <div
            className="image-wrapper"
            style={{ height: 166, backgroundImage: `url(${ShipyardGallery1})` }}
          ></div>
          <div
            className="image-wrapper"
            style={{ height: 200, backgroundImage: `url(${ShipyardGallery3})` }}
          ></div>
          <div
            className="image-wrapper"
            style={{ height: 303, backgroundImage: `url(${ShipyardGallery4})` }}
          ></div>
          <div
            className="image-wrapper"
            style={{ height: 123, backgroundImage: `url(${ShipyardGallery1})` }}
          ></div>
        </div>
        <div className="right-column d-flex w-50 flex-column">
          <div
            className="image-wrapper"
            style={{ height: 200, backgroundImage: `url(${ShipyardGallery2})` }}
          ></div>
          <div
            className="image-wrapper"
            style={{ height: 140, backgroundImage: `url(${ShipyardGallery4})` }}
          ></div>
          <div
            className="image-wrapper"
            style={{ height: 226, backgroundImage: `url(${ShipyardGallery1})` }}
          ></div>
          <div
            className="image-wrapper"
            style={{ height: 226, backgroundImage: `url(${ShipyardGallery2})` }}
          ></div>
        </div>
      </div>
      <div className="has-dark-background content-padding-x classification">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6">
              <h2 className="">Classification</h2>
              <p>
                This area holds ISO9001 quality management and security
                certificates issued by RINA and workshop permits. The operations
                continue under the supervision of RINA, LR, DNVGL (previously GL
                & DNV), ABS, BV, PRS, and other similar classification
                institutions.
              </p>
            </div>
            <div className="col-xl-6">
              <div className="logos h-100">
                <img src={RinaLogo} />
                <img src={IACSLogo} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-padding-x content-padding-y research">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6">
              <h2 className="">research & technology</h2>
              <p>
                Our engineering team actively improves existing technologies and
                develops new technologies. We regularly collaborate with local
                maritime academies and research institutes.
              </p>
            </div>
            <div className="col-xl-6">
              <div className="logos">
                <img src={MartimeLogo} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="has-dark-background content-padding-x content-padding-y services">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2 className="title-service">Service</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div
                className="service-item"
                style={{ backgroundImage: `url(${Service1})` }}
              >
                <p>Newly Built Designs & Conversions</p>
                <div className="service-item-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4">
              <div
                className="service-item"
                style={{ backgroundImage: `url(${Service2})` }}
              >
                <p>Mega Yachts: Chartering</p>
                <div className="service-item-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4">
              <div
                className="service-item"
                style={{ backgroundImage: `url(${Service3})` }}
              >
                <p>Research & Development</p>
                <div className="service-item-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4">
              <div
                className="service-item"
                style={{ backgroundImage: `url(${Service4})` }}
              >
                <p>Technical Support</p>
                <div className="service-item-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4">
              <div
                className="service-item"
                style={{ backgroundImage: `url(${Service5})` }}
              >
                <p>Naval Architecture & Marine Engineering Design</p>
                <div className="service-item-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4">
              <div
                className="service-item"
                style={{ backgroundImage: `url(${Service6})` }}
              >
                <p>Styling & Interior Design Services</p>
                <div className="service-item-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4">
              <div
                className="service-item"
                style={{ backgroundImage: `url(${Service7})` }}
              >
                <p>P&S and Management Services</p>
                <div className="service-item-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4">
              <div
                className="service-item"
                style={{ backgroundImage: `url(${Service8})` }}
              >
                <p>Pre-Owned Sales Department</p>
                <div className="service-item-overlay"></div>
              </div>
            </div>
            <div className="col-xl-4">
              <div
                className="service-item"
                style={{ backgroundImage: `url(${Service9})` }}
              >
                <p>After Sales Service</p>
                <div className="service-item-overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InnerPage>
  );
};

export default Shipyard;

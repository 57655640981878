/**
 * ACTION TYPES
 */
export const SET_STATE = 'SET_STATE';
export const TOGGLE_APP_MENU = 'TOGGLE_APP_MENU';



/**
 * ACTION CREATORS
 */
export const setState = newState => ({ type: SET_STATE, newState });
export const toggleAppMenu = () => ({ type: TOGGLE_APP_MENU });

export const homeSliderData = [
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/001.jpg',
    menuText: 'START EXPERIENCE',
    menuTextColor: 'dark',
    link: '/',
    videoSource: 'http://www.blaundusyachts.com/videos/homeVideo-1.mp4#t=0,3'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/002.jpg',
    menuText: 'MATERIAL',
    menuTextColor: 'light',
    link: '/',
    videoSource: 'http://www.blaundusyachts.com/videos/homeVideo-1.mp4#t=4,6'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/003.jpg',
    menuText: 'SPESIFICATION &<br>LAYOUT',
    menuTextColor: 'light',
    link: '/'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/004.jpg',
    menuText: 'NAVAL ARCHITECTURE &<br>DESIGN',
    menuTextColor: 'dark',
    link: '/'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/005.jpg',
    menuText: 'EXTERIOR',
    menuTextColor: 'dark',
    link: '/'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/006.jpg',
    menuText: 'FEATURES',
    menuTextColor: 'dark',
    link: '/'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/007.jpg',
    menuText: 'FIELD FEATURES',
    menuTextColor: 'dark',
    link: '/'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/008.jpg',
    menuText: 'BEACH CLUB',
    menuTextColor: 'dark',
    link: '/'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/009.jpg',
    menuText: 'INTERIOR',
    menuTextColor: 'dark',
    link: '/'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/010.jpg',
    menuText: '360° VIEWER',
    menuTextColor: 'light',
    link: '/'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/010.jpg',
    menuText: 'INTERIOR DESIGN',
    menuTextColor: 'dark',
    link: '/'
  },
  {
    title: 'Unique Design<br>& Modern Stripes',
    subtitle: '',
    image: '/images/home/banner/010.jpg',
    menuText: 'Shipyard',
    menuTextColor: 'shipyard-sneak-active',
    link: '/'
  }
];

export const interiorDesign = [
  {
    title: 'Owner Cabin',
    image: '/images/home/banner/001.jpg'
  },
  {
    title: 'Main Lounge',
    image: '/images/home/banner/002.jpg'
  },
  {
    title: 'Lorem Ipsum',
    image: '/images/home/banner/003.jpg'
  },
  {
    title: 'Owner Cabin',
    image: '/images/home/banner/001.jpg'
  }
];

export const blaundusSingle = [
  {
    image: '/images/blaundusSingleSlider/blaundus-big-1.jpg'
  },
  {
    image: '/images/blaundusSingleSlider/blaundus-big-1.jpg'
  },
  {
    image: '/images/blaundusSingleSlider/blaundus-big-1.jpg'
  },
  {
    image: '/images/blaundusSingleSlider/blaundus-big-1.jpg'
  }
];

export const exteriorGallery = [
  {
    image: '/images/home/banner/001.jpg'
  },
  {
    image: '/images/home/banner/002.jpg'
  },
  {
    image: '/images/home/banner/003.jpg'
  },
  {
    image: '/images/home/banner/001.jpg'
  }
];

export const interiorGallery = [
  {
    image: '/images/home/banner/003.jpg'
  },
  {
    image: '/images/home/banner/001.jpg'
  },
  {
    image: '/images/home/banner/003.jpg'
  },
  {
    image: '/images/home/banner/001.jpg'
  },
  {
    image: '/images/home/banner/003.jpg'
  }
];

export const blaundus30Gallery = [
  {
    image: '/images/exteriorImages/Exterior/exterior-1.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-2.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-3.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-4.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-5.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-6.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-7.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-8.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-9.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-10.jpg'
  },
  {
    image: '/images/exteriorImages/Exterior/exterior-11.jpg'
  }
];

export const b30_exterior = [
  {
    title: 'Beach Club',
    image: '/images/exteriorImages/BeachClub/beach-club-1.jpg'
  },
  {
    title: 'Fly Bridge',
    image: '/images/exteriorImages/FlyBridge/fly-bridge-1.jpg'
  },
  {
    title: 'Terrace',
    image: '/images/exteriorImages/Terrace/terrace-1.jpg'
  },
  {
    title: 'Beach Club',
    image: '/images/exteriorImages/BeachClub/beach-club-2.jpg'
  },
  {
    title: 'Fly Bridge',
    image: '/images/exteriorImages/FlyBridge/fly-bridge-2.jpg'
  },
  {
    title: 'Terrace',
    image: '/images/exteriorImages/Terrace/terrace-2.jpg'
  },
  {
    title: 'Beach Club',
    image: '/images/exteriorImages/BeachClub/beach-club-3.jpg'
  },
  {
    title: 'Terrace',
    image: '/images/exteriorImages/Terrace/terrace-3.jpg'
  },
  {
    title: 'Beach Club',
    image: '/images/exteriorImages/BeachClub/beach-club-4.jpg'
  },
  {
    title: 'Terrace',
    image: '/images/exteriorImages/Terrace/terrace-4.jpg'
  }
];

export const naval_interior_modern = [
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_1.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_2.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_3.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_4.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_5.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_6.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_7.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_8.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_9.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_10.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_11.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_12.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_13.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_14.jpg'
  },
  {
    title: 'Modern',
    image: '/images/interiorimages/modern/modern_15.jpg'
  }
];

export const naval_interior_classic = [
  {
    title: 'Owner Cabin',
    image: '/images/interiorimages/classic/owner_cabin/owner_cabin_1.jpg'
  },
  {
    title: 'Main Deck Lounge',
    image:
      '/images/interiorimages/classic/main_deck_lounge/main_deck_lounge_1.jpg'
  },
  {
    title: 'Master Cabin',
    image: '/images/interiorimages/classic/master_cabin/master_cabin_1.jpg'
  },
  {
    title: 'Guest Cabins Bathrooms',
    image:
      '/images/interiorimages/classic/guest_cabins_bathrooms/guest_cabins_bathrooms_1.jpg'
  },
  {
    title: 'Crew Mess',
    image: '/images/interiorimages/classic/crew_mess/crew_mess_1.jpg'
  },
  {
    title: 'VIP Cabin Bathroom',
    image:
      '/images/interiorimages/classic/vip_cabin_bathroom/vip_cabin_bathroom_1.jpg'
  },
  {
    title: 'Galley',
    image: '/images/interiorimages/classic/galley/galley_1.jpg'
  },
  {
    title: 'Owner Cabin Bathroom',
    image:
      '/images/interiorimages/classic/owner_cabin_bathroom/owner_cabin_bathroom_1.jpg'
  },
  {
    title: 'VIP Cabin',
    image: '/images/interiorimages/classic/vip_cabin/vip_cabin_1.jpg'
  },
  {
    title: 'Master Cabin Bathroom',
    image:
      '/images/interiorimages/classic/master_cabin_bathroom/master_cabin_bathroom_2.jpg'
  },
  {
    title: 'Owner Cabin',
    image: '/images/interiorimages/classic/owner_cabin/owner_cabin_2.jpg'
  },
  {
    title: 'Main Deck Lounge',
    image:
      '/images/interiorimages/classic/main_deck_lounge/main_deck_lounge_2.jpg'
  },
  {
    title: 'Master Cabin',
    image: '/images/interiorimages/classic/master_cabin/master_cabin_2.jpg'
  },
  {
    title: 'Day Head',
    image: '/images/interiorimages/classic/dayhead/dayhead_2.jpg'
  },
  {
    title: 'Guest Cabins Bathrooms',
    image:
      '/images/interiorimages/classic/guest_cabins_bathrooms/guest_cabins_bathrooms_2.jpg'
  },
  {
    title: 'VIP Cabin',
    image: '/images/interiorimages/classic/vip_cabin/vip_cabin_2.jpg'
  },
  {
    title: 'Galley',
    image: '/images/interiorimages/classic/galley/galley_2.jpg'
  },
  {
    title: 'Owner Cabin Bathroom',
    image:
      '/images/interiorimages/classic/owner_cabin_bathroom/owner_cabin_bathroom_2.jpg'
  },
  {
    title: 'VIP Cabin',
    image: '/images/interiorimages/classic/vip_cabin/vip_cabin_2.jpg'
  },
  {
    title: 'Master Cabin Bathroom',
    image:
      '/images/interiorimages/classic/master_cabin_bathroom/master_cabin_bathroom_3.jpg'
  },
  {
    title: 'Owner Cabin',
    image: '/images/interiorimages/classic/owner_cabin/owner_cabin_3.jpg'
  },
  {
    title: 'Main Deck Lounge',
    image:
      '/images/interiorimages/classic/main_deck_lounge/main_deck_lounge_3.jpg'
  },
  {
    title: 'Master Cabin',
    image: '/images/interiorimages/classic/master_cabin/master_cabin_3.jpg'
  },
  {
    title: 'Guest Cabins Bathrooms',
    image:
      '/images/interiorimages/classic/guest_cabins_bathrooms/guest_cabins_bathrooms_3.jpg'
  },
  {
    title: 'Galley',
    image: '/images/interiorimages/classic/galley/galley_3.jpg'
  },
  {
    title: 'Owner Cabin Bathroom',
    image:
      '/images/interiorimages/classic/owner_cabin_bathroom/owner_cabin_bathroom_3.jpg'
  },
];

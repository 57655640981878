import React, { Component } from 'react';
import Slider from 'react-slick';
import InnerPage from '../InnerPageWrapper';
import About1 from '../../assets/images/about-1.png';
import About2 from '../../assets/images/about-2.jpg';
import About3 from '../../assets/images/about-3.jpg';
import About4 from '../../assets/images/about-4.jpg';
import Gallery1 from '../../assets/images/about-us-gallery-1.jpg';
import Gallery2 from '../../assets/images/about-us-gallery-2.jpg';
import Gallery3 from '../../assets/images/about-us-gallery-3.jpg';
import Gallery4 from '../../assets/images/about-us-gallery-4.jpg';
import Gallery5 from '../../assets/images/about-us-gallery-5.jpg';
import Gallery6 from '../../assets/images/about-us-gallery-6.jpg';
import Gallery7 from '../../assets/images/about-us-gallery-7.jpg';
import Ancient1 from '../../assets/images/ancient-1.jpg';
import Ancient2 from '../../assets/images/ancient-2.jpg';
import Ancient3 from '../../assets/images/ancient-3.jpg';
import FatihAydin1 from '../../assets/images/fatih-aydin.png';
import EmrecanOzgun from '../../assets/images/emrecan-ozgun.png';
import ErolYuksel from '../../assets/images/erol-yuksel.png';
import TuncaySenyurt from '../../assets/images/tuncay-senyurt.png';
import './About.scss';
import AsNavFor from '../../components/AsNavFor';
import AboutVideo from '../../components/AboutVideo';

class About extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      persons: ['fatihAydin', 'emrecanOzgun', 'erolYuksel', 'tuncaySenyurt'],

      currentIndex: 0
    };
    this.state = this.initialState;
  }

  renderVideo = () => {
    switch (this.state.currentIndex) {
      case 0:
        return (
          <AboutVideo
            name="Fatih Aydın"
            title="Chairman of Board"
            videoSrc="396687623"
          />
        );
      case 1:
        return (
          <AboutVideo
            name="Emrecan Özgün"
            title="Designer"
            videoSrc="396686687"
          />
        );

      case 2:
        return (
          <AboutVideo
            name="Erol Yüksel"
            title="CEO of Blaundus Yachting"
            videoSrc="396685996"
          />
        );

      case 3:
        return (
          <AboutVideo
            name="Tuncay Şenyurt"
            title="Naval Architect & Marine Engineer"
            videoSrc="396687976"
          />
        );
      default:
        return (
          <AboutVideo
            name="Fatih Aydın"
            title="Chairman of Board"
            videoSrc="396687623"
          />
        );
    }
  };

  render() {
    const onylImgsettings = {
      responsive: [
        {
          breakpoint: 991,
          settings: 'unslick'
        }
      ]
    };

    let { currentIndex } = this.state;
    return (
      <InnerPage colorStyle="secondary">
        <div className="About">
          <div className="HeaderContent">
            <div className="HeaderImage">
              <div className="Mask" />
            </div>
            <h1 className="Title">ABOUT US</h1>
            <div className="Content">
              <p>
                Our company, was first engaged in overseas trip organizations
                for Turkey’s leading firms after its establishment in late 90’s
                under the name “V For Blue Exclusive Motor Yacht Charter Co.” as
                the first and only yacht renting services in Turkey with its
                completely privately owned motor yacht fleet. After a decade, we
                had already accommodated 80,000 people abroad in 98 countries.
                We made a name for ourselves as one of the fastest growing
                organizing companies of Turkey with an annual 27% growth rate,
                our dedication to quality standards, and capabilities to fulfill
                the touristic needs of 80 global and local companies.
              </p>
              <p className="d-none d-lg-block">
                We recently made the decision to restructure and change our name
                to Blaundus Yachting, with a new course towards super yacht
                construction after accumulating over 20 years of valuable
                experience in luxury yacht charter sector. The headquarters and
                the construction site of the company are located in Tuzla,
                Istanbul, one of the largest ship building and maintenance yards
                of Turkey.
              </p>
              <p className="d-none d-lg-block">
                Our second yacht management division VIP Corporate Yachting –
                Expedition Charters continues with its operations in Göcek,
                Muğla.{' '}
              </p>
            </div>
          </div>
          <div className="content-padding-x header-philosophy">
            <div className="row">
              <div className="col-lg-6">
                <div className="inner-label title-philosophy">Philosophy</div>
              </div>
              <div className="col-lg-6">
                <p className="inner-text text-philosophy">
                  The primary goal of Blaundus <br /> Yachting is ultimate
                  customer <br />
                  satisfaction
                </p>
              </div>
            </div>
          </div>
          <div className="content-padding-x about-row-wrapper image-left">
            <div className="row align-items-center">
              <div className="col-xl-5 d-flex justify-content-start">
                <div className="has-drop-shadow">
                  <img
                    src={About1}
                    className="img-fluid about-row-image about1"
                  />
                  <div className="shadow"></div>
                </div>
              </div>
              <div className="col-xl-6 offset-xl-1">
                <p className="text-uppercase title-about-image">
                  incomparable user experience
                </p>
                <p className="text-about-image">
                  Thanks to our experience of over 20 years with charters, we
                  know exactly what customers want for their life at sea, and we
                  design out yachts accordingly.
                </p>
              </div>
            </div>
          </div>
          <div className="content-padding-x about-row-wrapper image-right">
            <div className="row align-items-center flex-column-reverse flex-lg-row">
              <div className="col-xl-7">
                <p className="text-uppercase title-about-image">
                  THE BEST QUALITY AT THE BEST VALUE
                </p>
                <p className="text-about-image">
                  Yacht manufacturing in Turkey is at the crossroads where
                  quality and value meet. Combining highest quality materials
                  with experienced yet accessible talent is what makes Turkey an
                  attractive manufacturing location for the yacht industry.
                </p>
              </div>
              <div className="col-xl-5 d-flex justify-content-end">
                <div className="has-drop-shadow right">
                  <img
                    src={About2}
                    className="img-fluid about-row-image about2"
                  />
                  <div className="shadow"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-padding-x about-row-wrapper image-left">
            <div className="row align-items-center">
              <div className="col-xl-5 d-flex justify-content-start">
                <div className="has-drop-shadow">
                  <img
                    src={About3}
                    className="img-fluid about-row-image about3"
                  />
                  <div className="shadow"></div>
                </div>
              </div>
              <div className="col-xl-6 offset-xl-1">
                <p className="text-uppercase title-about-image">
                  PROFESSIONALISM
                </p>
                <p className="text-about-image">
                  As the team behind Blaundus Yachting, we have a unique
                  business know-how and experience in this sector. We are
                  professional, reliable, and business-oriented; which is
                  exactly what you need in your partner during the production
                  process of your yacht—and that can take up to 1.5-year.
                </p>
              </div>
            </div>
          </div>
          <div className="content-padding-x about-row-wrapper image-right">
            <div className="row align-items-center flex-column-reverse flex-lg-row">
              <div className="col-xl-7">
                <p className="text-uppercase title-about-image">
                  FUTURE-ORIENTED
                </p>
                <p className="text-about-image">
                  Our aim is to keep our finger on the pulse of the industry and
                  always strive for the better, newer, and more sustainable. Our
                  e-V hybrid yacht project is just one of our dreams to achieve
                  in near future.
                </p>
              </div>
              <div className="col-xl-5 d-flex justify-content-end">
                <div className="has-drop-shadow right">
                  <img
                    src={About4}
                    className="img-fluid about-row-image about4"
                  />
                  <div className="shadow"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="has-dark-background">
            {this.renderVideo()}
            <div className="row">
              <div className="col-12 thumb-items-wrapper">
                <div className="thumb-items d-flex justify-content-between">
                  <div
                    className={`thumb-item ${
                      currentIndex === 0 ? 'active-thumb' : ''
                    }`}
                  >
                    <button
                      className="d-flex justify-content-end flex-column h-100"
                      onClick={() => {
                        this.setState({ currentIndex: 0 });
                      }}
                    >
                      <h3>Fatih Aydın</h3>
                      <p>Chairman of the board</p>
                      <div className="thumb-overlay"></div>
                      <div
                        className="has-background-image"
                        style={{ backgroundImage: `url(${FatihAydin1})` }}
                      ></div>
                    </button>
                  </div>
                  <div
                    className={`thumb-item ${
                      currentIndex === 1 ? 'active-thumb' : ''
                    }`}
                  >
                    <button
                      className="d-flex justify-content-end flex-column h-100"
                      onClick={() => {
                        this.setState({ currentIndex: 1 });
                      }}
                    >
                      <h3>Emrecan Özgün</h3>
                      <p>Designer</p>
                      <div className="thumb-overlay"></div>
                      <div
                        className="has-background-image"
                        style={{ backgroundImage: `url(${EmrecanOzgun})` }}
                      ></div>
                    </button>
                  </div>
                  <div
                    className={`thumb-item ${
                      currentIndex === 2 ? 'active-thumb' : ''
                    }`}
                  >
                    <button
                      className="d-flex justify-content-end flex-column h-100"
                      onClick={() => {
                        this.setState({ currentIndex: 2 });
                      }}
                    >
                      <h3>Erol Yüksel</h3>
                      <p>Naval Architecture</p>
                      <div className="thumb-overlay"></div>
                      <div
                        className="has-background-image"
                        style={{ backgroundImage: `url(${ErolYuksel})` }}
                      ></div>
                    </button>
                  </div>
                  <div
                    className={`thumb-item ${
                      currentIndex === 3 ? 'active-thumb' : ''
                    }`}
                  >
                    <button
                      className="d-flex justify-content-end flex-column h-100"
                      onClick={() => {
                        this.setState({ currentIndex: 3 });
                      }}
                    >
                      <h3 lang="tr">Tuncay Şenyurt</h3>
                      <p>Naval Architect & Marine Engineer</p>
                      <div className="thumb-overlay"></div>
                      <div
                        className="has-background-image"
                        style={{ backgroundImage: `url(${TuncaySenyurt})` }}
                      ></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="has-dark-background about-interview">
            <AsNavFor />
          </div> */}
          <div className="has-gray-background">
            <div className="content-padding-x d-flex align-items-center excavations-wrapper">
              <div className="row">
                <div className="col-lg-6">
                  <div className="title-excavations">
                    Excavations at The ancient city of Blaundus
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text-excavations">
                    <p>
                      After Alexander the Great conquered the Aegean coast and
                      moved his campaign to the East, Roman commander Blaundus
                      founded a city named after him and started to rule the
                      region. Today, the remains of this once glorious city can
                      still be seen in Turkey’s Uşak province.
                    </p>
                    <p>
                      We are the proud sponsors of the archeological excavation
                      of the ancient City Blaundus in Ulubey, Uşak.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ancient-gallery-wrapper flex-wrap flex-lg-nowrap">
              <div
                className="ancient-vertical"
                style={{ backgroundImage: `url(${Ancient1})` }}
              ></div>
              <div
                className="ancient-horizontal"
                style={{ backgroundImage: `url(${Ancient2})` }}
              ></div>
              <div
                className="ancient-horizontal d-none d-lg-block"
                style={{ backgroundImage: `url(${Ancient3})` }}
              ></div>
            </div>
          </div>
          <div className="about-slider-last">
            <Slider
              slidesToShow={3}
              centerMode={true}
              centerPadding="120px"
              className="image-only-gallery"
              autoplay={true}
              autoplaySpeed={0}
              infinite={true}
              speed={50000}
              {...onylImgsettings}
            >
              <div className="image-only-item">
                <img src={Gallery1} className="img-fluid" />
              </div>
              <div className="image-only-item">
                <img src={Gallery2} className="img-fluid" />
              </div>
              <div className="image-only-item">
                <img src={Gallery4} className="img-fluid" />
              </div>
              <div className="image-only-item">
                <img src={Gallery7} className="img-fluid" />
              </div>
              <div className="image-only-item">
                <img src={Gallery5} className="img-fluid" />
              </div>
              <div className="image-only-item">
                <img src={Gallery6} className="img-fluid" />
              </div>
              <div className="image-only-item">
                <img src={Gallery3} className="img-fluid" />
              </div>
            </Slider>
            <div className="about-slider-last-mobile d-flex d-lg-none content-padding-x">
              <div className="left-column d-flex w-50 flex-column">
                <div
                  className="image-wrapper"
                  style={{ height: 237, backgroundImage: `url(${Gallery1})` }}
                ></div>
                <div
                  className="image-wrapper"
                  style={{ height: 211, backgroundImage: `url(${Gallery2})` }}
                ></div>
                <div
                  className="image-wrapper"
                  style={{ height: 135, backgroundImage: `url(${Gallery3})` }}
                ></div>
              </div>
              <div className="right-column d-flex w-50 flex-column">
                <div
                  className="image-wrapper"
                  style={{ height: 178, backgroundImage: `url(${Gallery4})` }}
                ></div>
                <div
                  className="image-wrapper"
                  style={{ height: 238, backgroundImage: `url(${Gallery5})` }}
                ></div>
                <div
                  className="image-wrapper"
                  style={{ height: 168, backgroundImage: `url(${Gallery6})` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </InnerPage>
    );
  }
}

export default About;

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import FooterLogo from '../../assets/images/blaundus-logo-footer.svg';
import LinkToExternal from '../../components/LinkToExternal';
import Facebook from '../../assets/images/facebook.svg';
import Twitter from '../../assets/images/twitter.svg';
import Instagram from '../../assets/images/instagram.svg';
import Youtube from '../../assets/images/youtube.svg';
import Linkedin from '../../assets/images/linkedin.svg';

const Footer = () => {
  return (
    <div className="content-padding-small-x">
      <div className="container-fluid footer">
        <div className="row">
          <div className="col-lg-3">
            <div className="footer-info">
              <a href="/">
                <img
                  src={FooterLogo}
                  alt="Blaundus Yachts"
                  className="footer-logo"
                />
              </a>
              <p>Volony Group Company</p>
            </div>
            <div className="justify-content-start social-share d-none d-lg-flex">
              <LinkToExternal
                href="https://www.facebook.com/blaundusyachts/"
                icon={Facebook}
                iconAlt="Blaundus Facebook Page"
              />
              <LinkToExternal
                href="https://twitter.com/blaundusyachts"
                icon={Twitter}
                iconAlt="Blaundus Twitter Page"
              />
              <LinkToExternal
                href="https://www.instagram.com/Blaundus.Yachts/"
                icon={Instagram}
                iconAlt="Blaundus Instagram Page"
              />
              <LinkToExternal
                href="https://www.youtube.com/channel/UChmxwjzGAlzIQZkhyI51VIg"
                icon={Youtube}
                iconAlt="Blaundus Youtube Page"
              />
              <LinkToExternal
                href="https://www.linkedin.com/company/blaundusyachts/"
                icon={Linkedin}
                iconAlt="Blaundus Linkedin Page"
              />
            </div>
          </div>
          <div className="col-lg-3">
            <ul className="list-unstyled footer-menu">
              <li>
                <a href="/about">About Us / Philosopy</a>
              </li>
              <li>
                <a href="/technical-specs">Specifications & Layout</a>
              </li>
              <li>
                <a href="/naval-architecture">
                  Naval Architecture & Design
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 d-flex justify-content-start justify-content-lg-center">
            <ul className="list-unstyled footer-menu">
              <li>
                <a href="/shipyard-service">The Shipyard & Service</a>
              </li>
              <li>
                <a href="/newbuild">New Build / Fleet</a>
              </li>
              <li>
                <a href="/#">News & Events</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 d-flex justify-content-start justify-content-lg-center">
            <ul className="list-unstyled footer-menu">
              <li>
                <a href="/contact">Contact & Careers</a>
              </li>
              <li>
                <a href="/terms-conditions">Terms & Conditions</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy & Policy</a>
              </li>
            </ul>
          </div>
          <div className="justify-content-start social-share d-flex d-lg-none col-12">
              <LinkToExternal
                href="https://facebook.com"
                icon={Facebook}
                iconAlt="Blaundus Facebook Page"
              />
              <LinkToExternal
                href="https://twitter.com"
                icon={Twitter}
                iconAlt="Blaundus Twitter Page"
              />
              <LinkToExternal
                href="https://instagram.com"
                icon={Instagram}
                iconAlt="Blaundus Instagram Page"
              />
              <LinkToExternal
                href="https://youtube.com"
                icon={Youtube}
                iconAlt="Blaundus Youtube Page"
              />
              <LinkToExternal
                href="https://linkedin.com"
                icon={Linkedin}
                iconAlt="Blaundus Linkedin Page"
              />
            </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

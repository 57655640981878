import React from 'react';
import { Link } from 'react-router-dom';
import NavalSneak from '../../../layouts/NavalSneak';
import PlusIcon from '../../../assets/images/plus-icon.svg';
import Page from '../../../layouts/Page/Page';

const Naval = () => {
  return (
    <Page>
      <div className="video-button-enabled">
        <Link to="/naval-architecture" className="slider-video-button">
          <button className="beta">
            <span>Architecture & Design</span>
            <img src={PlusIcon} alt="expand" />
          </button>
        </Link>
        <NavalSneak />
      </div>
    </Page>
  );
};

export default Naval;

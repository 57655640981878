import React, { useState, useRef } from 'react';
import InnerPage from '../InnerPageWrapper';
import Vimeo from '@u-wave/react-vimeo';
import SketchImage from '../../assets/images/naval-sketch.svg';
import NavalMeeting from '../../assets/images/naval-meeting.jpg';
import {
  blaundus30Gallery,
  naval_interior_modern,
  naval_interior_classic
} from '../../siteData';
import './NavalArchitecture.scss';
import BckgSlider from '../../components/BckgSlider';

const NavalArchitecture = () => {
  const [sliderContent, setSliderContent] = useState(naval_interior_modern);
  const interiorGalleryRef = useRef(null);

  return (
    <InnerPage>
      <div className="content-padding-x naval-sketch">
        <div className="row">
          <div className="col-12">
            <img src={SketchImage} alt="sketch" className="img-fluid" />
          </div>
        </div>
        <div className="row naval-text">
          <div className="col-xl-6">
            <div className="title-naval">
              Naval Architecture
              <br /> & Design
            </div>
          </div>
          <div className="col-xl-6">
            <div className="text-naval">
              Every corner of this yacht has been designed with only one thing
              on our mind: YOU! We put ourselves in your shoes and think about
              how you could make the best of your time on your yacht.
            </div>
          </div>
        </div>
      </div>
      <div className="has-dark-background naval-meeting">
        <div className="">
          <div className="row align-items-center flex-column-reverse flex-md-row">
            <div className="col-xl-6">
              <div className="naval-meeting-text">
                <p>
                  We believe that form cannot be separated from function, and
                  that they should always be in harmony. That’s why our yachts
                  are not only aesthetically pleasing, but also very
                  user-friendly. We are constantly in search of the optimum
                  balance between beauty and functionality. That is what makes
                  our designs timeless.
                </p>
                <p className="mt-5">As Charles Eames says,</p>
                <p className="quote">
                  “The details are not the details. They make the design.”
                </p>
              </div>
            </div>
            <div className="col-xl-6 d-flex justify-content-end align-items-center">
              <div className="naval-meeting-image">
                <img src={NavalMeeting} alt="desc" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="has-gray-background">
        <div className="content-padding-x naval-construction">
          <div className="naval-construction-title">Construction of yacht</div>
          <div className="naval-construction-video">
            {/* <Interview
              videoSrc="http://www.blaundusyachts.com/videos/construction.mp4"
              poster={Construction}
            /> */}
            <Vimeo video="396697900" responsive={true} showTitle={false} />
          </div>
        </div>
      </div>
      <div className="content-padding-x naval-interior">
        <div className="row">
          <div className="col-xl-6 d-flex flex-column justify-content-between">
            <p className="title-interior">Interior Design</p>
            <div className="slider-filter d-none d-md-block">
              <button
                className={
                  sliderContent === naval_interior_modern ? 'active-filter' : ''
                }
                onClick={() => {
                  setSliderContent(naval_interior_modern);
                  console.log(interiorGalleryRef.current);
                }}
              >
                modern
              </button>
              <span></span>
              <button
                className={
                  sliderContent === naval_interior_classic
                    ? 'active-filter'
                    : ''
                }
                onClick={() => {
                  setSliderContent(naval_interior_classic);
                }}
              >
                classic
              </button>
            </div>
          </div>
          <div className="col-xl-6 text-interior">
            <p>
              The priority of Blaundus, first and foremost, is customer
              satisfaction. Accordingly, we adopt “semi-custom design” approach
              four our products to offer our customers the opportunity to design
              the interior of their yachts freely and inventively.
            </p>
            <p>
              Customers who wish to choose from many existing modern, classic,
              or minimalist options may always consult our designer team.
            </p>
          </div>
        </div>
      </div>
      <div className="content-padding-left interior-gallery-wrapper">
        <BckgSlider
          sliderData={sliderContent}
          className="nav-bottom"
          overflowWidth="150"
          centerMode={true}
          slidesToShow={1}
          infinite={true}
          ref={interiorGalleryRef}
        />
      </div>
      <div className="row d-block d-md-none">
        <div className="col-12 d-flex justify-content-start">
          <div className="slider-filter filter-mobile naval-filter-mobile">
            <button
              className={
                sliderContent === naval_interior_modern ? 'active-filter' : ''
              }
              onClick={() => {
                setSliderContent(naval_interior_modern);
              }}
            >
              modern
            </button>
            <span></span>
            <button
              className={
                sliderContent === naval_interior_classic ? 'active-filter' : ''
              }
              onClick={() => {
                setSliderContent(naval_interior_classic);
              }}
            >
              classic
            </button>
          </div>
        </div>
      </div>
      <div className="content-padding-x naval-exterior">
        <div className="row">
          <div className="col-xl-12 title-exterior">exterior design</div>
        </div>
      </div>
      <div className="exterior-gallery-wrapper content-padding-left">
        <BckgSlider
          sliderData={blaundus30Gallery}
          className="nav-bottom"
          centerMode={true}
          slidesToShow={1}
          overflowWidth="150"
        />
      </div>
    </InnerPage>
  );
};

export default NavalArchitecture;

import React, { Component } from 'react';
import Material from '../HomeParts/Material';
import Experience from '../../components/HomeParts/Experience';
import TechnicalSneak from '../../layouts/TechnicalSneak';
import Naval from '../../components/HomeParts/Naval';
import Exterior from '../../components/HomeParts/Exterior';
import Features from '../../components/HomeParts/Features';
import FieldFeatures from '../../components/HomeParts/FieldFeatures';
import Interior from '../../components/HomeParts/Interior';
import SequenceViewer from '../../layouts/SequenceViewer';
import InteriorSneak from '../../layouts/InteriorSneak';
import BeachClub from '../../layouts/BeachClub';

class ActiveComponent extends Component {
  components = {
    Experience,
    Material,
    TechnicalSneak,
    Naval,
    Exterior,
    Features,
    FieldFeatures,
    Interior,
    SequenceViewer,
    InteriorSneak,
    BeachClub
  };

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    const Name = this.components[this.props.tag];
    return <Name />;
  }
}

export default ActiveComponent;

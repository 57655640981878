import React from 'react';
import VideoElement from '../../Video';
import Page from '../../../layouts/Page/Page';
import Mobile4 from '../../../assets/images/mobile_4.png';
import Poster from '../../../assets/images/poster_3.png';

const Features = props => {
  return (
    <Page>
      {window.innerWidth > 768 ? (
        <div>
          <VideoElement
            poster={Poster}
            videoSrc="http://www.blaundusyachts.com/videos/blaundus_4_1.mp4"
          />
          <div
            className="home-part-pre"
            style={{
              backgroundImage: `url(${Poster})`
            }}
          ></div>
        </div>
      ) : (
        <div
          className="has-background-image"
          style={{
            backgroundImage: `url(${Mobile4})`,
            height: props.mobile100vh
          }}
        ></div>
      )}
    </Page>
  );
};

export default Features;

import React, { Component } from 'react';
import Slider from 'react-slick';
import './TechnicalSpecs.scss';
import CaretRight from '../../assets/images/caret-right.svg';
import SunDeckTopView from '../../assets/images/sun-deck-top-view.svg';
import BridgeDeckTopView from '../../assets/images/bridge-deck-top-view.svg';
import MainDeckTopView from '../../assets/images/main-deck-top-view.svg';
import LowerDeckTopView from '../../assets/images/lower-deck-top-view.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import customArrow from '../../assets/images/gallery-arrow.svg';

class Technical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };

    this.planNav = React.createRef();
    this.planDots = React.createRef();
  }

  componentDidMount() {
    console.log(this.props.history);
  }

  innerHeight = () => {
    return window.innerHeight;
  };

  handleShow = () => {
    this.setState({ isActive: true });
  };

  handleHide = () => {
    this.setState({ isActive: false });
  };

  handleShowHide = () => {
    const { isActive } = this.state;
    if (!isActive) {
      this.setState({ isActive: true });
    } else this.setState({ isActive: false });
  };

  render() {
    const PrevArrow = props => {
      const { className, onClick } = props;
      return (
        <div className={className} onClick={onClick}>
          <img src={customArrow} alt="slider-prev" />
        </div>
      );
    };

    const NextArrow = props => {
      const { className, onClick } = props;
      return (
        <div className={className} onClick={onClick}>
          <img src={customArrow} alt="slider-prev" />
        </div>
      );
    };

    const techSlider = {
      className: this.state.isActive ? 'tech-slider specs-open' : 'tech-slider',
      arrows: true,
      dots: true,
      dotsClass: 'slick-dots plan-dots',
      fade: true,
      infinite: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 899,
          settings: {
            dots: false
          }
        }
      ],
      customPaging: i => {
        return (
          <div>
            <img src={`/images/planDots/plan${i}.svg`} />
          </div>
        );
      }
    };

    return (
      <div className="technical-wrapper" style={{ height: this.innerHeight() }}>
        <div className={`specs-trigger ${this.state.isActive ? 'active' : ''}`}>
          <div className="trigger-label">Specifications</div>
          <div className="trigger-button">
            <button onClick={this.handleShowHide} className="btn-icon-only">
              <span>
                {this.state.isActive ? 'close' : 'open'}
                &nbsp;detail
              </span>
              <img src={CaretRight} alt="techincal specs trigger" />
            </button>
          </div>
          <div className="spec-table">
            <div className="row">
              <div className="col-12">
                <h3>Specifications</h3>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-xl-6">
                    <ul className="list-unstyled spec-list">
                      <li className="list-header">Main Dimensions</li>
                      <li className="list-item">
                        <span>Loa</span>
                        <span>30 M</span>
                      </li>
                      <li className="list-item">
                        <span>LWL</span>
                        <span>27,599 M AT full load</span>
                      </li>
                      <li className="list-item">
                        <span>B Max</span>
                        <span>7,035 M</span>
                      </li>
                      <li className="list-item">
                        <span>DEPTHAMIDSHIP</span>
                        <span>3,83 M</span>
                      </li>
                      <li className="list-item">
                        <span>DRAUGHT, ATFULLLOAD</span>
                        <span>2,25 M ABT AT FULL LOAD</span>
                      </li>
                      <li className="list-item">
                        <span>DISPLACEMENT, FULL LOAD</span>
                        <span>220 T ABT</span>
                      </li>
                      <li className="list-item">
                        <span>GROSS TONNAGE (EXPECTED) (GT)</span>
                        <span>290 T</span>
                      </li>
                      <li className="list-item">
                        <span>CABIN CONFIGURATION</span>
                        <span>1 VIP / 1 Owner (Master) / 4 Guests</span>
                      </li>
                      <li className="list-header mt-3">Tanks Capacity</li>
                      <li className="list-item">
                        <span>FUEL OIL</span>
                        <span>27,059 m3</span>
                      </li>
                      <li className="list-item">
                        <span>FRESH WATER</span>
                        <span>66,490 m3</span>
                      </li>
                      <li className="list-item">
                        <span>GREY WATER</span>
                        <span>2,312 m3</span>
                      </li>
                      <li className="list-item">
                        <span>BLACK WATER</span>
                        <span>1,004 m3</span>
                      </li>
                      <li className="list-item">
                        <span>BILGE WATER</span>
                        <span>1,004 m3</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-6">
                    <ul className="list-unstyled spec-list">
                      <li className="list-header">Propulsion</li>
                      <li className="list-item">
                        <span>Propeller (Minumum Noise & Vibration)</span>
                        <span>CJR / UK</span>
                      </li>
                      <li className="list-item">
                        <span>MAIN ENGINES</span>
                        <span>
                          MANN 2x D2676 LE245, Medium Duty <br />
                          2x 478 kW (650 mhp) @ 2,100 rpm
                          <br />
                          2x ZF 3350&nbsp;&nbsp;&nbsp;&nbsp;2x GEAR RATID 4:1
                        </span>
                      </li>
                      <li className="list-item">
                        <span>B Max</span>
                        <span>7,035 M</span>
                      </li>
                      <li className="list-header mt-3">
                        Electrical Generating Plant
                      </li>
                      <li className="list-item">
                        <span>MAINGENSET</span>
                        <span>
                          1pcs x 55kW + 1pcs x 45 kW Kohler
                          <br />
                          380 V/50 Hz / Three Phase
                        </span>
                      </li>
                      <li className="list-header mt-3">Thrusters</li>
                      <li className="list-item">
                        <span>
                          SP 420 (U37) HYDRAULIC / 39 HP <br />
                          PRESSURE PULSES ON THE HULL
                        </span>
                        <span>420 kgf. (Minumum Noise & Vibration)</span>
                      </li>
                      <li className="list-item">
                        <span>(RELATED TO VIBRATIONS)</span>
                        <span>30 kW/ Norwgian</span>
                      </li>
                      <li className="list-header mt-3">Performances</li>
                      <li className="list-item">
                        <span>MAXIMUM CONTINUOS SPEED (100%MCR)</span>
                        <span>13,5 KNOTS</span>
                      </li>
                      <li className="list-item">
                        <span>CRUISING SPEED</span>
                        <span>9 KNOTS</span>
                      </li>
                      <li className="list-item">
                        <span>RANGE AT CRUISING SPEED</span>
                        <span>4500 NM</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`specs-main d-flex flex-column ${
            this.state.isActive ? 'shrinked' : ''
          }`}
        >
          <div className="d-flex justify-content-between main-header">
            <div>
              layout <br />
              <span>
                blaundus B<span>30</span>
              </span>
            </div>
            <button
              className="btn-icon-only"
              onClick={() => {
                this.props.setTechnical
                  ? this.props.setTechnical(false)
                  : this.props.history.push('/');
              }}
            >
              <img src={CloseIcon} alt="close specs main" />
            </button>
          </div>
          <div className="tech-slider-wrapper">
            <Slider {...techSlider} ref={this.planNav}>
              <div className="tech-slider-item">
                <div className="main-image">
                  <img
                    src={SunDeckTopView}
                    className="img-fluid w-100"
                    alt="Sun Deck Top View"
                  />
                  <div className="img-label">Sun Deck</div>
                </div>
              </div>
              <div className="tech-slider-item">
                <div className="main-image">
                  <img
                    src={BridgeDeckTopView}
                    className="img-fluid w-100"
                    alt="Birdge Deck Top View"
                  />
                  <div className="img-label">Bridge Deck</div>
                </div>
              </div>
              <div className="tech-slider-item">
                <div className="main-image">
                  <img
                    src={MainDeckTopView}
                    className="img-fluid w-100"
                    alt="Main Deck Top View"
                  />
                  <div className="img-label">Main Deck</div>
                </div>
              </div>
              <div className="tech-slider-item">
                <div className="main-image">
                  <img
                    src={LowerDeckTopView}
                    className="img-fluid w-100"
                    alt="Lower Deck Top View"
                  />
                  <div className="img-label">Lower Deck</div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Technical;

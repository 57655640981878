import * as actions from "./actions";

export const reducer = (state, action) => {

    console.log("reducer:",state,action);

    switch (action.type) {

        case actions.SET_STATE:
            return {
                ...state,
                ...action.newState,
            };

        default:
            return state;

    }
};